const FLOOR_7_DATA = [
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 34,
    sceneName: 'scene_A34_07krs__ita_0000',
    sceneName_future: 'scene_A34_07krs__ita_0000',
    name: 'A34',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 35,
    sceneName: 'scene_A35_07krs__lansi_0000',
    sceneName_future: 'scene_A35_07krs__lansi_0000',
    name: 'A35',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 7,
      4: 14,
      5: 21,
      6: 28,
      8: 41,
      9: 47,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 36,
    sceneName: 'scene_A36_07krs__lansi_0000',
    sceneName_future: 'scene_A36_07krs__lansi_0000',
    name: 'A36',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 8,
      4: 15,
      5: 22,
      6: 29,
      8: 42,
      9: 48,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 38,
    sceneName: 'scene_A38_07krs__etela_0000',
    sceneName_future: 'scene_A38_07krs__etela_0000',
    name: 'A38',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 3,
      3: 10,
      4: 17,
      5: 24,
      6: 31,
      8: 44,
      9: 50,
      10: 55,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 7,
    building: 'A',
    apartmentNumber: 39,
    sceneName: 'scene_A39_07krs__etela_0000',
    sceneName_future: 'scene_A39_07krs__etela_0000',
    name: 'A39',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 4,
      3: 11,
      4: 18,
      5: 25,
      6: 32,
      8: 45,
      9: 51,
      10: 56,
      11: 60,
      12: 64,
    },
  },
]

export default FLOOR_7_DATA
