const FLOOR_6_DATA = [
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 27,
    sceneName: 'scene_A27_06krs__ita_0000',
    sceneName_future: 'scene_A27_06krs__ita_0000',
    name: 'A27',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 28,
    sceneName: 'scene_A28_06krs__lansi_0000',
    sceneName_future: 'scene_A28_06krs__lansi_0000',
    name: 'A28',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 7,
      4: 14,
      5: 21,
      7: 35,
      8: 41,
      9: 47,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 29,
    sceneName: 'scene_A29_06krs__lansi_0000',
    sceneName_future: 'scene_A29_06krs__lansi_0000',
    name: 'A29',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 8,
      4: 15,
      5: 22,
      7: 36,
      8: 42,
      9: 48,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 31,
    sceneName: 'scene_A31_06krs__etela_0000',
    sceneName_future: 'scene_A31_06krs__etela_0000',
    name: 'A31',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 3,
      3: 10,
      4: 17,
      5: 24,
      7: 38,
      8: 44,
      9: 50,
      10: 55,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 32,
    sceneName: 'scene_A32_06krs__etela_0000',
    sceneName_future: 'scene_A32_06krs__etela_0000',
    name: 'A32',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 4,
      3: 11,
      4: 18,
      5: 25,
      7: 39,
      8: 45,
      9: 51,
      10: 56,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 6,
    building: 'A',
    apartmentNumber: 33,
    sceneName: 'scene_A33_06krs__ita_0000',
    sceneName_future: 'scene_A33_06krs__ita_0000',
    name: 'A33',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 12,
      4: 19,
      5: 26,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
]

export default FLOOR_6_DATA
