import * as React from 'react'
import styled from 'styled-components'

import NavigationPanel from 'components/NavigationPanel'

const TourWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1 !important;
`

export default function Krpano({ apartments, floors }) {
  const [krpano, setKrpano] = React.useState(null)
  const [hLookAt, setHLookAt] = React.useState(0)

  const krpano_onready_callback = React.useCallback(krpano_interface => {
    setKrpano(krpano_interface)
  }, [])

  React.useEffect(() => {
    const initKrpano = () => {
      window.embedpano({
        swf: 'vtour/tour.swf', // path to flash viewer (use null if no flash fallback will be required)
        id: 'krpanoSWFObject',
        xml: 'vtour/tour.xml',
        target: 'tour',
        bgcolor: '#FFFFFF',
        consolelog: false, // trace krpano messages also to the browser console
        passQueryParameters: true, // pass query parameters of the url to krpano
        onready: krpano_onready_callback,
      })
    }
    initKrpano()
  }, [krpano_onready_callback])

  React.useEffect(() => {
    const getHLookAt = () => {
      window.getHLookAt = krpano => {
        if (krpano) {
          setHLookAt(krpano.get('view.hlookat'))
        }
      }
    }
    getHLookAt()
  })

  return (
    <>
      <TourWrapper id="tour" />
      <NavigationPanel
        krpano={krpano}
        hLookAt={hLookAt + 108}
        apartments={apartments}
        floorsData={floors}
      />
    </>
  )
}

// const initialFloors = [
//   { id: 'floor-2', number: 2, isOpen: true },
//   { id: 'floor-3', number: 3, isOpen: true },
//   { id: 'floor-4', number: 4, isOpen: true },
//   { id: 'floor-5', number: 5, isOpen: true },
//   { id: 'floor-6', number: 6, isOpen: true },
//   { id: 'floor-7', number: 7, isOpen: true },
//   { id: 'floor-8', number: 8, isOpen: true },
//   { id: 'floor-9', number: 9, isOpen: true },
//   { id: 'floor-10', number: 10, isOpen: true },
//   { id: 'floor-11', number: 11, isOpen: true },
//   { id: 'floor-12', number: 12, isOpen: true },
// ]

// const initialApartments = [
//   {
//     id: 'A1',
//     status: 'available',
//     floor: 2,
//     building: 'A',
//     number: 1,
//     sceneName: 'scene_A01_02krs__lansi_0000',
//     sceneName_future: 'scene_A01_02krs__lansi_0000',
//     name: 'A1',
//     lookAt: '-92, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       3: 8,
//       4: 15,
//       5: 22,
//       6: 29,
//       7: 36,
//       8: 42,
//       9: 48,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A3',
//     status: 'available',
//     floor: 2,
//     building: 'A',
//     number: 3,
//     sceneName: 'scene_A03_02krs__etela_0000',
//     sceneName_future: 'scene_A03_02krs__etela_0000',
//     name: 'A3',
//     lookAt: '-92, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       3: 10,
//       4: 17,
//       5: 24,
//       6: 31,
//       7: 38,
//       8: 44,
//       9: 50,
//       10: 55,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A4',
//     status: 'available',
//     floor: 2,
//     building: 'A',
//     number: 4,
//     sceneName: 'scene_A04_02krs__etela_0000',
//     sceneName_future: 'scene_A04_02krs__etela_0000',
//     name: 'A4',
//     lookAt: '88, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       3: 11,
//       4: 18,
//       5: 25,
//       6: 32,
//       7: 39,
//       8: 45,
//       9: 51,
//       10: 56,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A5',
//     status: 'available',
//     floor: 2,
//     building: 'A',
//     number: 5,
//     sceneName: 'scene_A05_02krs__ita_0000',
//     sceneName_future: 'scene_A05_02krs__ita_0000',
//     name: 'A5',
//     lookAt: '88, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       3: 12,
//       4: 19,
//       5: 26,
//       6: 33,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A6',
//     status: 'available',
//     floor: 3,
//     building: 'A',
//     number: 6,
//     sceneName: 'scene_A06_03krs__ita_0000',
//     sceneName_future: 'scene_A06_03krs__ita_0000',
//     name: 'A6',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 5,
//       4: 13,
//       5: 20,
//       6: 27,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A7',
//     status: 'available',
//     floor: 3,
//     building: 'A',
//     number: 7,
//     sceneName: 'scene_A07_03krs__lansi_0000',
//     sceneName_future: 'scene_A07_03krs__lansi_0000',
//     name: 'A7',
//     lookAt: '21, 0, 140',
//     face: 'south',

//     relatedApartments: {
//       2: 1,
//       4: 14,
//       5: 21,
//       6: 28,
//       7: 35,
//       8: 41,
//       9: 47,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A8',
//     status: 'available',
//     floor: 3,
//     building: 'A',
//     number: 8,
//     sceneName: 'scene_A08_03krs__lansi_0000',
//     sceneName_future: 'scene_A08_03krs__lansi_0000',
//     name: 'A8',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 1,
//       4: 15,
//       5: 22,
//       6: 29,
//       7: 36,
//       8: 42,
//       9: 48,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A10',
//     status: 'available',
//     floor: 3,
//     building: 'A',
//     number: 10,
//     sceneName: 'scene_A10_03krs__etela_0000',
//     sceneName_future: 'scene_A10_03krs__etela_0000',
//     name: 'A10',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 3,
//       4: 17,
//       5: 24,
//       6: 31,
//       7: 38,
//       8: 44,
//       9: 50,
//       10: 55,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A11',
//     status: 'available',
//     floor: 3,
//     building: 'A',
//     number: 11,
//     sceneName: 'scene_A11_03krs__etela_0000',
//     sceneName_future: 'scene_A11_03krs__etela_0000',
//     name: 'A11',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 4,
//       4: 18,
//       5: 25,
//       6: 32,
//       7: 39,
//       8: 45,
//       9: 51,
//       10: 56,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A12',
//     status: 'available',
//     floor: 3,
//     building: 'A',
//     number: 12,
//     sceneName: 'scene_A12_03krs__ita_0000',
//     sceneName_future: 'scene_A12_03krs__ita_0000',
//     name: 'A12',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       4: 19,
//       5: 26,
//       6: 33,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A13',
//     status: 'available',
//     floor: 4,
//     building: 'A',
//     number: 13,
//     sceneName: 'scene_A13_04krs__ita_0000',
//     sceneName_future: 'scene_A13_04krs__ita_0000',
//     name: 'A13',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 6,
//       5: 20,
//       6: 27,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A14',
//     status: 'available',
//     floor: 4,
//     building: 'A',
//     number: 14,
//     sceneName: 'scene_A14_04krs__lansi_0000',
//     sceneName_future: 'scene_A14_04krs__lansi_0000',
//     name: 'A14',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 7,
//       5: 21,
//       6: 28,
//       7: 35,
//       8: 41,
//       9: 47,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A15',
//     status: 'available',
//     floor: 4,
//     building: 'A',
//     number: 15,
//     sceneName: 'scene_A15_04krs__lansi_0000',
//     sceneName_future: 'scene_A15_04krs__lansi_0000',
//     name: 'A15',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 8,
//       5: 22,
//       6: 29,
//       7: 36,
//       8: 42,
//       9: 48,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A17',
//     status: 'available',
//     floor: 4,
//     building: 'A',
//     number: 17,
//     sceneName: 'scene_A17_04krs__etela_0000',
//     sceneName_future: 'scene_A17_04krs__etela_0000',
//     name: 'A17',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 3,
//       3: 10,
//       5: 24,
//       6: 31,
//       7: 38,
//       8: 44,
//       9: 50,
//       10: 55,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A18',
//     status: 'available',
//     floor: 4,
//     building: 'A',
//     number: 18,
//     sceneName: 'scene_A18_04krs__etela_0000',
//     sceneName_future: 'scene_A18_04krs__etela_0000',
//     name: 'A18',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 4,
//       3: 11,
//       5: 25,
//       6: 32,
//       7: 39,
//       8: 45,
//       9: 51,
//       10: 56,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A19',
//     status: 'available',
//     floor: 4,
//     building: 'A',
//     number: 19,
//     sceneName: 'scene_A19_04krs__ita_0000',
//     sceneName_future: 'scene_A19_04krs__ita_0000',
//     name: 'A19',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 12,
//       5: 26,
//       6: 33,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A20',
//     status: 'available',
//     floor: 5,
//     building: 'A',
//     number: 20,
//     sceneName: 'scene_A20_05krs__ita_0000',
//     sceneName_future: 'scene_A20_05krs__ita_0000',
//     name: 'A20',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 6,
//       4: 13,
//       6: 27,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A21',
//     status: 'available',
//     floor: 5,
//     building: 'A',
//     number: 21,
//     sceneName: 'scene_A21_05krs__lansi_0000',
//     sceneName_future: 'scene_A21_05krs__lansi_0000',
//     name: 'A21',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 7,
//       4: 14,
//       6: 28,
//       7: 35,
//       8: 41,
//       9: 47,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A22',
//     status: 'available',
//     floor: 5,
//     building: 'A',
//     number: 22,
//     sceneName: 'scene_A20_05krs__lansi_0000',
//     sceneName_future: 'scene_A20_05krs__lansi_0000',
//     name: 'A22',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 8,
//       4: 15,
//       6: 29,
//       7: 36,
//       8: 42,
//       9: 48,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A24',
//     status: 'available',
//     floor: 5,
//     building: 'A',
//     number: 24,
//     sceneName: 'scene_A24_05krs__etela_0000',
//     sceneName_future: 'scene_A24_05krs__etela_0000',
//     name: 'A24',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 3,
//       3: 10,
//       4: 17,
//       6: 31,
//       7: 38,
//       8: 44,
//       9: 50,
//       10: 55,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A25',
//     status: 'available',
//     floor: 5,
//     building: 'A',
//     number: 25,
//     sceneName: 'scene_A25_05krs__etela_0000',
//     sceneName_future: 'scene_A25_05krs__etela_0000',
//     name: 'A25',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 4,
//       3: 11,
//       4: 18,
//       6: 32,
//       7: 39,
//       8: 45,
//       9: 51,
//       10: 56,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A26',
//     status: 'available',
//     floor: 5,
//     building: 'A',
//     number: 26,
//     sceneName: 'scene_A26_05krs__ita_0000',
//     sceneName_future: 'scene_A26_05krs__ita_0000',
//     name: 'A26',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 12,
//       4: 19,
//       6: 33,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A27',
//     status: 'available',
//     floor: 6,
//     building: 'A',
//     number: 27,
//     sceneName: 'scene_A27_06krs__ita_0000',
//     sceneName_future: 'scene_A27_06krs__ita_0000',
//     name: 'A27',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 6,
//       4: 13,
//       5: 20,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A28',
//     status: 'available',
//     floor: 6,
//     building: 'A',
//     number: 28,
//     sceneName: 'scene_A28_06krs__lansi_0000',
//     sceneName_future: 'scene_A28_06krs__lansi_0000',
//     name: 'A28',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 7,
//       4: 14,
//       5: 21,
//       7: 35,
//       8: 41,
//       9: 47,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A29',
//     status: 'available',
//     floor: 6,
//     building: 'A',
//     number: 29,
//     sceneName: 'scene_A29_06krs__lansi_0000',
//     sceneName_future: 'scene_A29_06krs__lansi_0000',
//     name: 'A29',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 8,
//       4: 15,
//       5: 22,
//       7: 36,
//       8: 42,
//       9: 48,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A31',
//     status: 'available',
//     floor: 6,
//     building: 'A',
//     number: 31,
//     sceneName: 'scene_A31_06krs__etela_0000',
//     sceneName_future: 'scene_A31_06krs__etela_0000',
//     name: 'A31',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 3,
//       3: 10,
//       4: 17,
//       5: 24,
//       7: 38,
//       8: 44,
//       9: 50,
//       10: 55,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A32',
//     status: 'available',
//     floor: 6,
//     building: 'A',
//     number: 32,
//     sceneName: 'scene_A32_06krs__etela_0000',
//     sceneName_future: 'scene_A32_06krs__etela_0000',
//     name: 'A32',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 4,
//       3: 11,
//       4: 18,
//       5: 25,
//       7: 39,
//       8: 45,
//       9: 51,
//       10: 56,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A33',
//     status: 'available',
//     floor: 6,
//     building: 'A',
//     number: 33,
//     sceneName: 'scene_A33_06krs__ita_0000',
//     sceneName_future: 'scene_A33_06krs__ita_0000',
//     name: 'A33',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 12,
//       4: 19,
//       5: 26,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A34',
//     status: 'available',
//     floor: 7,
//     building: 'A',
//     number: 34,
//     sceneName: 'scene_A34_07krs__ita_0000',
//     sceneName_future: 'scene_A34_07krs__ita_0000',
//     name: 'A34',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 6,
//       4: 13,
//       5: 20,
//       6: 27,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A35',
//     status: 'available',
//     floor: 7,
//     building: 'A',
//     number: 35,
//     sceneName: 'scene_A35_07krs__lansi_0000',
//     sceneName_future: 'scene_A35_07krs__lansi_0000',
//     name: 'A35',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 7,
//       4: 14,
//       5: 21,
//       6: 28,
//       8: 41,
//       9: 47,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A36',
//     status: 'available',
//     floor: 7,
//     building: 'A',
//     number: 36,
//     sceneName: 'scene_A36_07krs__lansi_0000',
//     sceneName_future: 'scene_A36_07krs__lansi_0000',
//     name: 'A36',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 8,
//       4: 15,
//       5: 22,
//       6: 29,
//       8: 42,
//       9: 48,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A38',
//     status: 'available',
//     floor: 7,
//     building: 'A',
//     number: 38,
//     sceneName: 'scene_A38_07krs__etela_0000',
//     sceneName_future: 'scene_A38_07krs__etela_0000',
//     name: 'A38',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 3,
//       3: 10,
//       4: 17,
//       5: 24,
//       6: 31,
//       8: 44,
//       9: 50,
//       10: 55,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A39',
//     status: 'available',
//     floor: 7,
//     building: 'A',
//     number: 39,
//     sceneName: 'scene_A39_07krs__etela_0000',
//     sceneName_future: 'scene_A39_07krs__etela_0000',
//     name: 'A39',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 4,
//       3: 11,
//       4: 18,
//       5: 25,
//       6: 32,
//       8: 45,
//       9: 51,
//       10: 56,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A40',
//     status: 'available',
//     floor: 8,
//     building: 'A',
//     number: 40,
//     sceneName: 'scene_A40_08krs__ita_0000',
//     sceneName_future: 'scene_A40_08krs__ita_0000',
//     name: 'A40',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 6,
//       4: 13,
//       5: 20,
//       6: 27,
//       7: 34,
//       9: 46,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A41',
//     status: 'available',
//     floor: 8,
//     building: 'A',
//     number: 41,
//     sceneName: 'scene_A41_08krs__lansi_0000',
//     sceneName_future: 'scene_A41_08krs__lansi_0000',
//     name: 'A41',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 7,
//       4: 14,
//       5: 21,
//       6: 28,
//       7: 35,
//       9: 47,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A42',
//     status: 'available',
//     floor: 8,
//     building: 'A',
//     number: 42,
//     sceneName: 'scene_A42_08krs__lansi_0000',
//     sceneName_future: 'scene_A42_08krs__lansi_0000',
//     name: 'A42',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 8,
//       4: 15,
//       5: 22,
//       6: 29,
//       7: 36,
//       9: 48,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A44',
//     status: 'available',
//     floor: 8,
//     building: 'A',
//     number: 44,
//     sceneName: 'scene_A44_08krs__etela_0000',
//     sceneName_future: 'scene_A44_08krs__etela_0000',
//     name: 'A44',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 3,
//       3: 10,
//       4: 17,
//       5: 24,
//       6: 31,
//       7: 38,
//       9: 50,
//       10: 55,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A45',
//     status: 'available',
//     floor: 8,
//     building: 'A',
//     number: 45,
//     sceneName: 'scene_A45_08krs__etela_0000',
//     sceneName_future: 'scene_A45_08krs__etela_0000',
//     name: 'A45',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 4,
//       3: 11,
//       4: 18,
//       5: 25,
//       6: 32,
//       7: 39,
//       9: 51,
//       10: 56,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A46',
//     status: 'available',
//     floor: 9,
//     building: 'A',
//     number: 46,
//     sceneName: 'scene_A46_09krs__ita_0000',
//     sceneName_future: 'scene_A46_09krs__ita_0000',
//     name: 'A46',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 6,
//       4: 13,
//       5: 20,
//       6: 27,
//       7: 34,
//       8: 40,
//       10: 52,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A47',
//     status: 'available',
//     floor: 9,
//     building: 'A',
//     number: 47,
//     sceneName: 'scene_A47_09krs__lansi_0000',
//     sceneName_future: 'scene_A47_09krs__lansi_0000',
//     name: 'A47',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 7,
//       4: 14,
//       5: 21,
//       6: 28,
//       7: 35,
//       8: 41,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A48',
//     status: 'available',
//     floor: 9,
//     building: 'A',
//     number: 48,
//     sceneName: 'scene_A48_09krs__lansi_0000',
//     sceneName_future: 'scene_A48_09krs__lansi_0000',
//     name: 'A48',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 8,
//       4: 15,
//       5: 22,
//       6: 29,
//       7: 36,
//       8: 42,
//       10: 53,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A50',
//     status: 'available',
//     floor: 9,
//     building: 'A',
//     number: 50,
//     sceneName: 'scene_A50_09krs__etela_0000',
//     sceneName_future: 'scene_A50_09krs__etela_0000',
//     name: 'A50',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 3,
//       3: 10,
//       4: 17,
//       5: 24,
//       6: 31,
//       7: 38,
//       8: 44,
//       10: 55,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A51',
//     status: 'available',
//     floor: 9,
//     building: 'A',
//     number: 51,
//     sceneName: 'scene_A51_09krs__etela_0000',
//     sceneName_future: 'scene_A51_09krs__etela_0000',
//     name: 'A51',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 4,
//       3: 11,
//       4: 18,
//       5: 25,
//       6: 32,
//       7: 39,
//       8: 45,
//       10: 56,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A52',
//     status: 'available',
//     floor: 10,
//     building: 'A',
//     number: 52,
//     sceneName: 'scene_A52_10krs__ita_0000',
//     sceneName_future: 'scene_A52_10krs__ita_0000',
//     name: 'A52',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 6,
//       4: 13,
//       5: 20,
//       6: 27,
//       7: 34,
//       8: 40,
//       9: 46,
//       11: 57,
//       12: 61,
//     },
//   },
//   {
//     id: 'A53',
//     status: 'available',
//     floor: 10,
//     building: 'A',
//     number: 53,
//     sceneName: 'scene_A53_10krs__lansi_0000',
//     sceneName_future: 'scene_A53_10krs__lansi_0000',
//     name: 'A53',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 7,
//       4: 14,
//       5: 21,
//       6: 28,
//       7: 35,
//       8: 41,
//       9: 47,
//       11: 58,
//       12: 62,
//     },
//   },
//   {
//     id: 'A55',
//     status: 'available',
//     floor: 10,
//     building: 'A',
//     number: 55,
//     sceneName: 'scene_A55_10krs__etela_0000',
//     sceneName_future: 'scene_A55_10krs__etela_0000',
//     name: 'A55',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 3,
//       3: 10,
//       4: 17,
//       5: 24,
//       6: 31,
//       7: 38,
//       8: 44,
//       9: 50,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A56',
//     status: 'available',
//     floor: 10,
//     building: 'A',
//     number: 56,
//     sceneName: 'scene_A56_10krs__etela_0000',
//     sceneName_future: 'scene_A56_10krs__etela_0000',
//     name: 'A56',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 4,
//       3: 11,
//       4: 18,
//       5: 25,
//       6: 32,
//       7: 39,
//       8: 45,
//       9: 51,
//       11: 60,
//       12: 64,
//     },
//   },
//   {
//     id: 'A57',
//     status: 'available',
//     floor: 11,
//     building: 'A',
//     number: 57,
//     sceneName: 'scene_A57_11krs__ita_0000',
//     sceneName_future: 'scene_A57_11krs__ita_0000',
//     name: 'A57',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 6,
//       4: 13,
//       5: 20,
//       6: 27,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       12: 61,
//     },
//   },
//   {
//     id: 'A58',
//     status: 'available',
//     floor: 11,
//     building: 'A',
//     number: 58,
//     sceneName: 'scene_A58_11krs__lansi_0000',
//     sceneName_future: 'scene_A58_11krs__lansi_0000',
//     name: 'A58',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 7,
//       4: 14,
//       5: 21,
//       6: 28,
//       7: 35,
//       8: 41,
//       9: 47,
//       10: 53,
//       12: 62,
//     },
//   },
//   {
//     id: 'A60',
//     status: 'available',
//     floor: 11,
//     building: 'A',
//     number: 60,
//     sceneName: 'scene_A60_11krs__etela_0000',
//     sceneName_future: 'scene_A60_11krs__etela_0000',
//     name: 'A60',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 3,
//       3: 10,
//       4: 17,
//       5: 24,
//       6: 31,
//       7: 38,
//       8: 44,
//       9: 50,
//       10: 56,
//       12: 64,
//     },
//   },
//   {
//     id: 'A61',
//     status: 'available',
//     floor: 12,
//     building: 'A',
//     number: 61,
//     sceneName: 'scene_A61_12krs__ita_0000',
//     sceneName_future: 'scene_A61_12krs__ita_0000',
//     name: 'A61',
//     lookAt: '52.5, 0, 140',
//     face: 'north',

//     relatedApartments: {
//       2: 5,
//       3: 6,
//       4: 13,
//       5: 20,
//       6: 27,
//       7: 34,
//       8: 40,
//       9: 46,
//       10: 52,
//       11: 57,
//     },
//   },
//   {
//     id: 'A62',
//     status: 'available',
//     floor: 12,
//     building: 'A',
//     number: 62,
//     sceneName: 'scene_A62_12krs__lansi_0000',
//     sceneName_future: 'scene_A62_12krs__lansi_0000',
//     name: 'A62',
//     lookAt: '-109, 0, 140',
//     face: 'west',

//     relatedApartments: {
//       2: 1,
//       3: 7,
//       4: 14,
//       5: 21,
//       6: 28,
//       7: 35,
//       8: 41,
//       9: 47,
//       10: 53,
//       11: 58,
//     },
//   },
//   {
//     id: 'A64',
//     status: 'available',
//     floor: 12,
//     building: 'A',
//     number: 64,
//     sceneName: 'scene_A64_12krs__etela_0000',
//     sceneName_future: 'scene_A64_12krs__etela_0000',
//     name: 'A64',
//     lookAt: '160, 0, 140',
//     face: 'east',

//     relatedApartments: {
//       2: 3,
//       3: 10,
//       4: 17,
//       5: 24,
//       6: 31,
//       7: 38,
//       8: 44,
//       9: 50,
//       10: 56,
//       11: 60,
//     },
//   },
// ]
