import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor16({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
  apartments,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="floor-16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 210.4068146 162"
      xmlSpace="preserve"
      enableBackground="new 0 0 210.4068146 162"
    >
      <g id="bg_00000108307422098023357540000013088164682706780573_">
        <g id="static_00000084511480166620829490000010687659778987666575_">
          <rect
            id="plate_00000096026814640883107040000005802735016844991109_"
            className="st0"
            width="210.4068146"
            height="162"
          />
        </g>
      </g>
      {/* APARTMENTS */}
      <g id="apartments">
        <polygon
          id="A121"
          className={`st1 ${
            getApartmentStatus(apartments, 'A121') === 'sold' ? 'bg-gray' : ''
          }`}
          points="124.2772293,73.3623657
		115.1435928,73.3623657 108.8317032,73.3623657 88.871315,73.3623657 88.871315,88.8673096 99.9208145,88.8673096
		99.9208145,108.2039795 115.1435928,108.2039795 115.1435928,90.4415894 124.2772293,90.4415894 	"
        />

        <polygon
          id="A120"
          className={`st1 ${
            getApartmentStatus(apartments, 'A120') === 'sold' ? 'bg-gray' : ''
          }`}
          points="115.1435928,57.7683105
		115.1435928,56.0752563 88.871315,56.0752563 88.871315,73.3623657 109.8118668,73.3623657 115.1435928,73.3623657
		124.2772293,73.3623886 124.2772293,57.7683334 	"
        />

        <g
          onClick={() => changeView(16, 119)}
          onMouseOver={() => setElementOnHover('A119')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A119"
            className={`st1 ${
              sceneName === 'scene_A119_16krs_9_present' ||
              sceneName === 'scene_A119_16krs_9_future'
                ? 'active-apt'
                : elementOnHover === 'A119'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A119') === 'sold' ? 'bg-gray' : ''
            }`}
            points="108.4752579,21.4118652
		108.4752579,14.6395874 93.1485367,14.6395874 93.1485367,21.4118652 88.871315,21.4118652 88.871315,56.0752563
		115.1435928,56.0752563 115.1435928,21.4118652 	"
          />
        </g>

        <polygon
          id="A118"
          className={`st1 ${
            getApartmentStatus(apartments, 'A118') === 'sold' ? 'bg-gray' : ''
          }`}
          points="45.6237564,51.1148567
		88.8712845,51.1148567 88.8712845,28.1841679 81.4752502,28.184164 81.4752426,21.4118881 45.6237564,21.4118881 	"
        />

        <rect
          id="A117"
          x="45.6237564"
          y="51.1148567"
          width="35.7623825"
          height="33.5049515"
          className={`st1 ${
            getApartmentStatus(apartments, 'A117') === 'sold' ? 'bg-gray' : ''
          }`}
        />

        <polygon
          id="A106"
          className={`st1 ${
            getApartmentStatus(apartments, 'A106') === 'sold' ? 'bg-gray' : ''
          }`}
          points="70.5148697,98.6693115
		70.5148697,84.619812 61.4851456,84.619812 54.1437149,84.619812 45.6237564,84.619812 45.6237564,140.9366455
		54.1437149,140.9366455 81.3861465,140.9366455 81.3861465,98.6693115 	"
        />

        <polygon
          id="A115"
          className={`st1 ${
            getApartmentStatus(apartments, 'A115') === 'sold' ? 'bg-gray' : ''
          }`}
          points="99.9208145,108.2039795
		99.9208145,118.71875 81.3861465,118.718811 81.3861465,140.9366455 84.237648,140.9366455 84.237648,150.085144
		104.3168106,150.085144 104.3168106,140.9366455 105.8019791,140.9366455 115.1435928,140.9366455 115.1435928,108.2039795 	"
        />
      </g>
      <g id="levelfloor_00000155139252943852816680000015800166236393142164_">
        <rect
          x="54.1437416"
          y="21.9799557"
          className="st3"
          width="60.9998245"
          height="118.3905792"
        />
        <line
          className="st4"
          x1="54.1437416"
          y1="51.1148567"
          x2="45.623764"
          y2="51.1148567"
        />
        <polyline
          className="st4"
          points="53.7029686,140.9366455 45.623764,140.9366455 45.623764,21.4118881 54.1437416,21.4118881 	"
        />
        <rect
          x="54.1437416"
          y="21.9799557"
          className="st5"
          width="34.7275467"
          height="29.134901"
        />
        <rect
          x="88.8712845"
          y="21.9799557"
          className="st5"
          width="26.2722778"
          height="34.0952988"
        />
        <rect
          x="54.1437416"
          y="51.1148567"
          className="st5"
          width="27.2423973"
          height="33.5049515"
        />
        <rect
          x="88.8712845"
          y="56.0752525"
          className="st5"
          width="26.2722778"
          height="17.2871284"
        />
        <polygon
          className="st5"
          points="88.8712845,88.8673325 88.8712845,73.362381 115.1435623,73.362381 115.1435623,108.2039642
		99.9207916,108.2039642 99.9207916,88.8673325 	"
        />
        <polygon
          className="st5"
          points="81.3861389,140.3705292 81.3861389,118.7188187 99.9207916,118.7187576 99.9207916,108.2039642
		115.1435623,108.2039642 115.1435623,140.3705292 	"
        />
        <polygon
          className="st5"
          points="54.1437416,140.3705292 81.3861389,140.3705292 81.3861389,98.6693115 70.5148544,98.6693115
		70.5148544,84.6198044 54.1437416,84.6198044 	"
        />
        <path className="st6" d="M45.623764,84.6198044h8.5199776H45.623764z" />
        <rect
          x="70.5148544"
          y="84.6198044"
          className="st4"
          width="10.8712873"
          height="14.0495052"
        />
        <rect
          x="81.4752502"
          y="21.9799557"
          className="st4"
          width="7.3960395"
          height="6.2042079"
        />
        <rect
          x="71.0197983"
          y="84.9762421"
          className="st5"
          width="9.9801979"
          height="13.3069305"
        />
        <rect
          x="81.8910904"
          y="22.5443134"
          className="st7"
          width="6.5346537"
          height="5.0754952"
        />
        <rect
          x="54.1437416"
          y="21.9799557"
          className="st8"
          width="60.9998245"
          height="118.3905792"
        />
        <polyline
          className="st4"
          points="84.2376251,140.3705292 84.2376251,150.0851593 104.3168335,150.0851593 104.3168335,140.3705292
		"
        />
        <polyline
          className="st4"
          points="93.1485138,21.9799557 93.1485138,14.6396103 108.4752502,14.6396103 108.4752502,21.9799557 	"
        />
        <polyline
          className="st4"
          points="115.4851456,57.768322 124.2772293,57.768322 124.2772293,73.3623886 124.2772293,90.4415894
		115.4851456,90.4415894 	"
        />
        <line
          className="st4"
          x1="115.1435623"
          y1="73.362381"
          x2="124.2772293"
          y2="73.3623886"
        />
        <line
          className="st5"
          x1="88.8712845"
          y1="93.9019852"
          x2="88.8712845"
          y2="113.6396103"
        />
        <line
          className="st5"
          x1="94.5297012"
          y1="93.9019852"
          x2="94.5297012"
          y2="113.6396103"
        />
      </g>
      {/* APARTMENTS NUMBERS */}
      <g id="apartment_nr_00000053514714429794987220000003222000896868381825_">
        <g
          id="apartment_nr_A118"
          className={
            getApartmentStatus(apartments, 'A118') === 'sold'
              ? 'text-white'
              : ''
          }
        >
          <path
            className={`st2 ${
              getApartmentStatus(apartments, 'A118') === 'sold'
                ? 'text-white'
                : ''
            }`}
            d="M63.7539444,38.8050842l1.2485352-3.9111328h0.5395508l1.2626953,3.9111328H66.364296l-0.3930664-1.2070313
			H64.57621l-0.3793945,1.2070313H63.7539444z M65.2681046,35.2953186l-0.5727539,1.9345703h1.1567383l-0.5727539-1.9345703
			H65.2681046z"
          />
          <path
            className="st2"
            d="M68.5356827,38.8050842v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H68.5356827z"
          />
          <path
            className="st2"
            d="M71.6533585,38.8050842v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H71.6533585z"
          />
          <path
            className="st2"
            d="M74.7925186,38.8685608c-0.7485352,0-1.1225586-0.3427734-1.1225586-1.0273438
			c0-0.4951172,0.2246094-0.7910156,0.6748047-0.8867188v-0.0107422c-0.3999023-0.1220703-0.6000977-0.4140625-0.6000977-0.8769531
			c0-0.2753906,0.0922852-0.4951172,0.2763672-0.6611328c0.1918945-0.171875,0.4526367-0.2578125,0.7827148-0.2578125
			c0.3095703,0,0.5600586,0.0800781,0.7519531,0.2382813c0.1967773,0.1660156,0.2954102,0.3886719,0.2954102,0.6689453
			c0,0.2734375-0.0708008,0.4921875-0.2128906,0.65625c-0.1103516,0.1269531-0.2412109,0.2041016-0.3925781,0.2324219v0.0107422
			c0.1640625,0.03125,0.3071289,0.1083984,0.4287109,0.2304688c0.1640625,0.1660156,0.2460938,0.390625,0.2460938,0.6728516
			C75.9204483,38.5316467,75.5444717,38.8685608,74.7925186,38.8685608z M74.1099014,37.8128967
			c0,0.4863281,0.2333984,0.7294922,0.6992188,0.7294922c0.4472656,0,0.6713867-0.2412109,0.6713867-0.7246094
			c0-0.4707031-0.2294922-0.7070313-0.6879883-0.7070313c-0.2128906,0-0.3798828,0.0615234-0.5009766,0.1855469
			C74.1704483,37.419342,74.1099014,37.591217,74.1099014,37.8128967z M74.1846085,36.1390686
			c0,0.4306641,0.2119141,0.6455078,0.6357422,0.6455078c0.1831055,0,0.3271484-0.0566406,0.4326172-0.1699219
			s0.1582031-0.2695313,0.1582031-0.4697266c0-0.4462891-0.2060547-0.6699219-0.6186523-0.6699219
			c-0.1904297,0-0.3393555,0.0605469-0.4467773,0.1806641S74.1846085,35.9369202,74.1846085,36.1390686z"
          />
        </g>

        <g
          id="apartment_nr_A116"
          className={
            getApartmentStatus(apartments, 'A116') === 'sold'
              ? 'text-white'
              : ''
          }
        >
          <path
            className="st2"
            d="M63.7539444,131.5267639l1.2485352-3.9111328h0.5395508l1.2626953,3.9111328H66.364296l-0.3930664-1.2070313
			H64.57621l-0.3793945,1.2070313H63.7539444z M65.2681046,128.0169983l-0.5727539,1.9345703h1.1567383l-0.5727539-1.9345703
			H65.2681046z"
          />
          <path
            className="st2"
            d="M68.5356827,131.5267639v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H68.5356827z"
          />
          <path
            className="st2"
            d="M71.6533585,131.5267639v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H71.6533585z"
          />
          <path
            className="st2"
            d="M74.1763077,129.5179749v0.2646484h0.0112305c0.1806641-0.2822266,0.4677734-0.4238281,0.8608398-0.4238281
			c0.3286133,0,0.5805664,0.1103516,0.7558594,0.3300781c0.1586914,0.2001953,0.2382813,0.4638672,0.2382813,0.7929688
			c0,0.3378906-0.1020508,0.6074219-0.3061523,0.8076172c-0.2041016,0.2011719-0.4672852,0.3007813-0.7905273,0.3007813
			c-0.4853516,0-0.8208008-0.1884766-1.0073242-0.5644531c-0.1367188-0.2773438-0.2050781-0.7070313-0.2050781-1.2900391
			c0-0.5761719,0.0756836-1.0107422,0.2270508-1.3037109c0.1958008-0.375,0.527832-0.5625,0.996582-0.5625
			c0.3544922,0,0.6230469,0.1064453,0.8056641,0.3173828c0.1328125,0.1513672,0.1992188,0.3134766,0.1992188,0.4853516h-0.4926758
			c0-0.1025391-0.0351563-0.2001953-0.1049805-0.2900391c-0.0961914-0.1240234-0.2373047-0.1855469-0.4238281-0.1855469
			c-0.2841797,0-0.4907227,0.1494141-0.6201172,0.4492188C74.2246475,128.8685608,74.1763077,129.1595764,74.1763077,129.5179749z
			 M74.2427139,130.4750061c0,0.234375,0.0610352,0.4228516,0.1831055,0.5644531
			c0.1279297,0.1494141,0.3017578,0.2246094,0.5214844,0.2246094c0.4365234,0,0.6547852-0.2617188,0.6547852-0.7832031
			c0-0.5341797-0.2216797-0.8007813-0.6655273-0.8007813c-0.2148438,0-0.3837891,0.0732422-0.5078125,0.2207031
			C74.3047256,130.0472717,74.2427139,130.238678,74.2427139,130.4750061z"
          />
        </g>

        <g
          id="apartment_nr_A119"
          className={`
            ${
              sceneName === 'scene_A119_16krs_9_present' ||
              sceneName === 'scene_A119_16krs_9_future'
                ? 'text-white'
                : elementOnHover === 'A119'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A119') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(16, 119)}
          onMouseOver={() => setElementOnHover('A119')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="st2"
            d="M98.3149796,38.8675842l1.2485352-3.9111328h0.5395508l1.2626953,3.9111328h-0.4404297l-0.3930664-1.2070313
			h-1.3950195l-0.3793945,1.2070313H98.3149796z M99.8291397,35.3578186l-0.5727539,1.9345703h1.1567383l-0.5727539-1.9345703
			H99.8291397z"
          />
          <path
            className="st2"
            d="M103.0967178,38.8675842v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H103.0967178z"
          />
          <path
            className="st2"
            d="M106.2143936,38.8675842v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H106.2143936z"
          />
          <path
            className="st2"
            d="M110.0190811,37.2777405v-0.2617188h-0.0107422
			c-0.1904297,0.2841797-0.4760742,0.4257813-0.8583984,0.4257813c-0.3300781,0-0.5820313-0.1103516-0.7558594-0.3320313
			c-0.1567383-0.2001953-0.2348633-0.4658203-0.2348633-0.7988281c0-0.3359375,0.1020508-0.6044922,0.3071289-0.8037109
			c0.1992188-0.1972656,0.4614258-0.296875,0.7861328-0.296875c0.4853516,0,0.8203125,0.1875,1.0048828,0.5625
			c0.1347656,0.2744141,0.2021484,0.7060547,0.2021484,1.2929688c0,0.5751953-0.0756836,1.0087891-0.2270508,1.3007813
			c-0.1938477,0.3759766-0.5244141,0.5644531-0.9912109,0.5644531c-0.355957,0-0.6264648-0.1064453-0.8110352-0.3193359
			c-0.1328125-0.1533203-0.1992188-0.3164063-0.1992188-0.4892578h0.5063477c0,0.1054688,0.0332031,0.203125,0.1000977,0.2939453
			c0.09375,0.125,0.2324219,0.1884766,0.4150391,0.1884766C109.76371,38.6048889,110.0190811,38.1625061,110.0190811,37.2777405z
			 M108.5991592,36.3109436c0,0.5361328,0.2192383,0.8046875,0.6572266,0.8046875
			c0.2182617,0,0.3891602-0.0732422,0.5131836-0.2197266s0.1860352-0.3398438,0.1860352-0.5791016
			c0-0.2304688-0.0600586-0.4160156-0.1801758-0.5585938c-0.1279297-0.1474609-0.3027344-0.2207031-0.5244141-0.2207031
			C108.8164444,35.5375061,108.5991592,35.7953186,108.5991592,36.3109436z"
          />
        </g>

        <g
          id="apartment_nr_A121"
          className={
            getApartmentStatus(apartments, 'A121') === 'sold'
              ? 'text-white'
              : ''
          }
        >
          <path
            className="st2"
            d="M98.3398819,82.7713928l1.2485352-3.9111328h0.5395508l1.2626953,3.9111328h-0.4404297l-0.3930664-1.2070313
			h-1.3950195l-0.3793945,1.2070313H98.3398819z M99.8540421,79.2616272l-0.5727539,1.9345703h1.1567383l-0.5727539-1.9345703
			H99.8540421z"
          />
          <path
            className="st2"
            d="M103.1216202,82.7713928v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H103.1216202z"
          />
          <path
            className="st2"
            d="M105.1572647,82.7713928v-0.3789063c0.3447266-0.2822266,0.6679688-0.6015625,0.96875-0.9580078
			c0.4614258-0.5458984,0.6918945-1.0009766,0.6918945-1.3652344c0-0.1806641-0.0478516-0.3271484-0.144043-0.4404297
			c-0.1049805-0.125-0.2553711-0.1875-0.4511719-0.1875c-0.2197266,0-0.3798828,0.0693359-0.4814453,0.2089844
			c-0.0722656,0.0976563-0.1079102,0.2099609-0.1079102,0.3359375h-0.4760742
			c0.0073242-0.2099609,0.078125-0.390625,0.2128906-0.5419922c0.1899414-0.2197266,0.4833984-0.3300781,0.8803711-0.3300781
			c0.2988281,0,0.5415039,0.0908203,0.7280273,0.2714844s0.2797852,0.4228516,0.2797852,0.7255859
			c0,0.4169922-0.21875,0.8828125-0.65625,1.3974609c-0.1547852,0.1826172-0.4541016,0.4755859-0.8969727,0.8779297h1.6054688
			v0.3847656H105.1572647z"
          />
          <path
            className="st2"
            d="M109.35746,82.7713928v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H109.35746z"
          />
        </g>

        <g
          id="apartment_nr_A117"
          className={
            getApartmentStatus(apartments, 'A117') === 'sold'
              ? 'text-white'
              : ''
          }
        >
          <path
            className="st2"
            d="M63.5547256,77.2499084l1.2485352-3.9111328h0.5395508l1.2626953,3.9111328h-0.4404297l-0.3930664-1.2070313
			h-1.3950195l-0.3793945,1.2070313H63.5547256z M65.0688858,73.7401428l-0.5727539,1.9345703h1.1567383l-0.5727539-1.9345703
			H65.0688858z"
          />
          <path
            className="st2"
            d="M68.3364639,77.2499084v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H68.3364639z"
          />
          <path
            className="st2"
            d="M71.4541397,77.2499084v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H71.4541397z"
          />
          <path
            className="st2"
            d="M73.8223038,77.2499084l1.3730469-3.2080078h-1.6499023V73.657135h2.1147461v0.3623047l-1.3671875,3.2304688
			H73.8223038z"
          />
        </g>

        <g
          id="apartment_nr_A120"
          className={
            getApartmentStatus(apartments, 'A120') === 'sold'
              ? 'text-white'
              : ''
          }
        >
          <path
            className="st2"
            d="M98.2881241,65.2596741l1.2485352-3.9111328h0.5395508l1.2626953,3.9111328h-0.4404297l-0.3930664-1.2070313
			h-1.3950195l-0.3793945,1.2070313H98.2881241z M99.8022842,61.7499084l-0.5727539,1.9345703h1.1567383l-0.5727539-1.9345703
			H99.8022842z"
          />
          <path
            className="st2"
            d="M103.0698624,65.2596741v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793v3.5927734
			H103.0698624z"
          />
          <path
            className="st2"
            d="M105.1055069,65.2596741v-0.3789063c0.3447266-0.2822266,0.6679688-0.6015625,0.96875-0.9580078
			c0.4614258-0.5458984,0.6918945-1.0009766,0.6918945-1.3652344c0-0.1806641-0.0478516-0.3271484-0.144043-0.4404297
			c-0.1049805-0.125-0.2553711-0.1875-0.4511719-0.1875c-0.2197266,0-0.3798828,0.0693359-0.4814453,0.2089844
			c-0.0722656,0.0976563-0.1079102,0.2099609-0.1079102,0.3359375h-0.4760742
			c0.0073242-0.2099609,0.078125-0.390625,0.2128906-0.5419922c0.1899414-0.2197266,0.4833984-0.3300781,0.8803711-0.3300781
			c0.2988281,0,0.5415039,0.0908203,0.7280273,0.2714844s0.2797852,0.4228516,0.2797852,0.7255859
			c0,0.4169922-0.21875,0.8828125-0.65625,1.3974609c-0.1547852,0.1826172-0.4541016,0.4755859-0.8969727,0.8779297h1.6054688
			v0.3847656H105.1055069z"
          />
          <path
            className="st2"
            d="M109.3281631,65.3231506c-0.815918,0-1.2236328-0.6201172-1.2236328-1.8603516
			s0.4077148-1.8603516,1.2236328-1.8603516c0.8154297,0,1.2236328,0.6201172,1.2236328,1.8603516
			S110.1435928,65.3231506,109.3281631,65.3231506z M108.5444717,63.4627991c0,1.0224609,0.2612305,1.5341797,0.7836914,1.5341797
			c0.5219727,0,0.7832031-0.5117188,0.7832031-1.5341797s-0.2612305-1.5332031-0.7832031-1.5332031
			C108.8057022,61.9295959,108.5444717,62.4403381,108.5444717,63.4627991z"
          />
        </g>

        <g
          id="apartment_nr_A115"
          className={
            getApartmentStatus(apartments, 'A115') === 'sold'
              ? 'text-white'
              : ''
          }
        >
          <path
            className="st2"
            d="M98.3906631,131.5267639l1.2485352-3.9111328h0.5395508l1.2626953,3.9111328h-0.4404297l-0.3930664-1.2070313
			h-1.3950195l-0.3793945,1.2070313H98.3906631z M99.9048233,128.0169983l-0.5727539,1.9345703h1.1567383l-0.5727539-1.9345703
			H99.9048233z"
          />
          <path
            className="st2"
            d="M103.1724014,131.5267639v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793
			v3.5927734H103.1724014z"
          />
          <path
            className="st2"
            d="M106.2900772,131.5267639v-3.1582031l-0.6806641,0.4589844v-0.4287109l0.7416992-0.4648438h0.362793
			v3.5927734H106.2900772z"
          />
          <path
            className="st2"
            d="M109.0044327,128.3187561l-0.0498047,1.2041016c0.1787109-0.1162109,0.3769531-0.1748047,0.5947266-0.1748047
			c0.3544922,0,0.6269531,0.1113281,0.8168945,0.3330078c0.1733398,0.2050781,0.2602539,0.4755859,0.2602539,0.8115234
			c0,0.3515625-0.112793,0.625-0.3378906,0.8203125c-0.2104492,0.1855469-0.4853516,0.2773438-0.824707,0.2773438
			c-0.3950195,0-0.6904297-0.1044922-0.8857422-0.3134766c-0.1425781-0.1503906-0.2133789-0.3164063-0.2133789-0.5h0.5039063
			c0,0.1123047,0.043457,0.2158203,0.1303711,0.3095703c0.1088867,0.1181641,0.2626953,0.1777344,0.4604492,0.1777344
			c0.484375,0,0.7265625-0.2617188,0.7265625-0.7832031c0-0.28125-0.0712891-0.4921875-0.2133789-0.6347656
			c-0.1201172-0.1181641-0.2719727-0.1767578-0.4550781-0.1767578c-0.253418,0-0.4428711,0.0947266-0.5688477,0.2851563h-0.4316406
			l0.0966797-2.0205078h1.7802734v0.3847656H109.0044327z"
          />
        </g>
      </g>
      {/* CONES */}
      <g id="view_7_">
        {apartmentNumber === 119 ? (
          <g id="A119_x5F_7">
            <linearGradient
              id="cone_00000137851674305556590710000014853069884358287537_"
              gradientUnits="userSpaceOnUse"
              x1="165.5325623"
              y1="279.3538818"
              x2="165.5325623"
              y2="234.4438629"
              gradientTransform="matrix(0 1 1 0 -186.7878265 -148.038559)"
            >
              <stop offset="0.0102" style={{ stopColor: '#FFFFFF' }} />
              <stop
                offset="1"
                style={{ stopColor: '#FFFFFF', stopOpacity: 0 }}
              />
            </linearGradient>

            <path
              id="cone_00000057826566459935565090000007868637560427103110_"
              style={{
                fill: 'url(#cone_00000137851674305556590710000014853069884358287537_)',
              }}
              d="
			M92.5460281,20.8690071c-0.3699951-0.8899994-0.5800018-1.8600006-0.5800018-2.8899994
			c0-1.0400009,0.2100067-2.0299997,0.6000061-2.9199991L47.6560326-4.40099v43.7900009L92.5460281,20.8690071z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />

            <circle
              id="location_00000183933383480157000510000015795671649538632639_"
              className={`st10 ${
                sceneName === 'scene_A119_16krs_9_present' ||
                sceneName === 'scene_A119_16krs_9_future'
                  ? 'circle-white'
                  : elementOnHover === 'A119'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.6600037"
            />
          </g>
        ) : null}
      </g>
      {/* Switch */}
      <g id="state_7_">
        <g id="tulevaisuus_00000031904759575858858080000007856425911922546345_">
          <path
            className="st2"
            d="M144.0391083,138.676178v-4.375h-1.1552734v-0.4541016h2.8535156v0.4541016h-1.1542969v4.375H144.0391083z"
          />
          <path
            className="st2"
            d="M149.6797333,133.8470764v3.2744141c0,0.4580078-0.0771484,0.8085938-0.2324219,1.0527344
			c-0.2412109,0.3867188-0.6855469,0.5810547-1.3349609,0.5810547c-0.9833984,0-1.4746094-0.5449219-1.4746094-1.6337891v-3.2744141
			h0.5439453v3.1738281c0,0.3837891,0.0429688,0.6650391,0.1298828,0.8447266
			c0.1435547,0.2900391,0.4169922,0.4345703,0.8212891,0.4345703c0.4404297,0,0.7333984-0.1445313,0.8769531-0.4345703
			c0.0839844-0.1679688,0.1269531-0.4501953,0.1269531-0.8447266v-3.1738281H149.6797333z"
          />
          <path
            className="st2"
            d="M151.1787567,138.676178v-4.8291016h0.5439453v4.375h1.7285156v0.4541016H151.1787567z"
          />
          <path
            className="st2"
            d="M154.378952,138.676178v-4.8291016h2.3994141v0.4541016h-1.8554688v1.6171875h1.7089844v0.4541016h-1.7089844
			v1.8496094h1.8554688v0.4541016H154.378952z"
          />
          <path
            className="st2"
            d="M158.8311005,138.676178l-1.5751953-4.8291016h0.5605469l1.3564453,4.234375h0.0136719l1.3808594-4.234375
			h0.5605469l-1.6103516,4.8291016H158.8311005z"
          />
          <path
            className="st2"
            d="M161.4199677,138.676178l1.5419922-4.8291016h0.6660156l1.5585938,4.8291016h-0.5429688l-0.4863281-1.4902344
			h-1.7226563l-0.4677734,1.4902344H161.4199677z M163.2900848,134.3421936l-0.7080078,2.3896484h1.4287109l-0.7070313-2.3896484
			H163.2900848z"
          />
          <path
            className="st2"
            d="M166.229538,138.676178v-4.8291016h0.5429688v4.8291016H166.229538z"
          />
          <path
            className="st2"
            d="M169.4375458,135.8187561l0.4511719,0.2460938c0.3300781,0.1826172,0.5615234,0.3417969,0.6943359,0.4785156
			c0.21875,0.2304688,0.328125,0.5136719,0.328125,0.8515625c0,0.4716797-0.171875,0.8232422-0.5136719,1.0556641
			c-0.2949219,0.203125-0.6914063,0.3046875-1.1914063,0.3046875c-0.3007813,0-0.6728516-0.0488281-1.1152344-0.1464844v-0.5576172
			c0.421875,0.1660156,0.8144531,0.2490234,1.1777344,0.2490234c0.3076172,0,0.5566406-0.0703125,0.7460938-0.2119141
			c0.1982422-0.1513672,0.2978516-0.3554688,0.2978516-0.6132813c0-0.2421875-0.0830078-0.4453125-0.25-0.6103516
			c-0.09375-0.0888672-0.2783203-0.2109375-0.5546875-0.3652344l-0.4277344-0.2392578
			c-0.3515625-0.1962891-0.5820313-0.3447266-0.6914063-0.4443359c-0.2353516-0.21875-0.3525391-0.4882813-0.3525391-0.8066406
			c0-0.4033203,0.1484375-0.7148438,0.4453125-0.9335938c0.2753906-0.2050781,0.6464844-0.3076172,1.1123047-0.3076172
			c0.2666016,0,0.6015625,0.0419922,1.0058594,0.1269531v0.5400391c-0.3398438-0.1416016-0.6914063-0.2119141-1.0556641-0.2119141
			c-0.2763672,0-0.4970703,0.0664063-0.6621094,0.1992188c-0.1650391,0.1337891-0.2480469,0.3134766-0.2480469,0.5390625
			c0,0.1865234,0.0869141,0.3574219,0.2597656,0.5126953C168.9941864,135.559967,169.175827,135.6752014,169.4375458,135.8187561z"
          />
          <path
            className="st2"
            d="M175.0752411,133.8470764v3.2744141c0,0.4580078-0.0771484,0.8085938-0.2324219,1.0527344
			c-0.2412109,0.3867188-0.6855469,0.5810547-1.3349609,0.5810547c-0.9833984,0-1.4746094-0.5449219-1.4746094-1.6337891v-3.2744141
			h0.5439453v3.1738281c0,0.3837891,0.0429688,0.6650391,0.1298828,0.8447266
			c0.1435547,0.2900391,0.4169922,0.4345703,0.8212891,0.4345703c0.4404297,0,0.7333984-0.1445313,0.8769531-0.4345703
			c0.0839844-0.1679688,0.1269531-0.4501953,0.1269531-0.8447266v-3.1738281H175.0752411z"
          />
          <path
            className="st2"
            d="M179.5742645,133.8470764v3.2744141c0,0.4580078-0.0771484,0.8085938-0.2324219,1.0527344
			c-0.2412109,0.3867188-0.6855469,0.5810547-1.3349609,0.5810547c-0.9833984,0-1.4746094-0.5449219-1.4746094-1.6337891v-3.2744141
			h0.5439453v3.1738281c0,0.3837891,0.0429688,0.6650391,0.1298828,0.8447266
			c0.1435547,0.2900391,0.4169922,0.4345703,0.8212891,0.4345703c0.4404297,0,0.7333984-0.1445313,0.8769531-0.4345703
			c0.0839844-0.1679688,0.1269531-0.4501953,0.1269531-0.8447266v-3.1738281H179.5742645z"
          />
          <path
            className="st2"
            d="M182.1523895,135.8187561l0.4511719,0.2460938c0.3300781,0.1826172,0.5615234,0.3417969,0.6943359,0.4785156
			c0.21875,0.2304688,0.328125,0.5136719,0.328125,0.8515625c0,0.4716797-0.171875,0.8232422-0.5136719,1.0556641
			c-0.2949219,0.203125-0.6914063,0.3046875-1.1914063,0.3046875c-0.3007813,0-0.6728516-0.0488281-1.1152344-0.1464844v-0.5576172
			c0.421875,0.1660156,0.8144531,0.2490234,1.1777344,0.2490234c0.3076172,0,0.5566406-0.0703125,0.7460938-0.2119141
			c0.1982422-0.1513672,0.2978516-0.3554688,0.2978516-0.6132813c0-0.2421875-0.0830078-0.4453125-0.25-0.6103516
			c-0.09375-0.0888672-0.2783203-0.2109375-0.5546875-0.3652344l-0.4277344-0.2392578
			c-0.3515625-0.1962891-0.5820313-0.3447266-0.6914063-0.4443359c-0.2353516-0.21875-0.3525391-0.4882813-0.3525391-0.8066406
			c0-0.4033203,0.1484375-0.7148438,0.4453125-0.9335938c0.2753906-0.2050781,0.6464844-0.3076172,1.1123047-0.3076172
			c0.2666016,0,0.6015625,0.0419922,1.0058594,0.1269531v0.5400391c-0.3398438-0.1416016-0.6914063-0.2119141-1.0556641-0.2119141
			c-0.2763672,0-0.4970703,0.0664063-0.6621094,0.1992188c-0.1650391,0.1337891-0.2480469,0.3134766-0.2480469,0.5390625
			c0,0.1865234,0.0869141,0.3574219,0.2597656,0.5126953C181.7090302,135.559967,181.8906708,135.6752014,182.1523895,135.8187561z"
          />
        </g>
        <g id="nykyisyys_00000141454652250438127060000002702683770719698079_">
          <path
            className="st2"
            d="M147.2646942,91.9706116v-4.8295898h0.7001953l1.9902344,4.0810547h0.0136719v-4.0810547h0.515625v4.8295898
			h-0.7011719l-1.9853516-4.0673828h-0.0136719v4.0673828H147.2646942z"
          />
          <path
            className="st2"
            d="M152.8984833,91.9706116v-2.2250977l-1.5039063-2.6044922h0.5947266l1.1806641,2.0507813l1.2021484-2.0507813
			h0.59375l-1.5234375,2.6044922v2.2250977H152.8984833z"
          />
          <path
            className="st2"
            d="M155.8740692,91.9706116v-4.8295898h0.5439453v2.190918h0.0136719l1.6953125-2.190918h0.6347656
			l-1.8046875,2.3173828l1.9521484,2.512207h-0.6455078l-1.8320313-2.3652344h-0.0136719v2.3652344H155.8740692z"
          />
          <path
            className="st2"
            d="M160.7324677,91.9706116v-2.2250977l-1.5039063-2.6044922h0.5947266l1.1806641,2.0507813l1.2021484-2.0507813
			h0.59375l-1.5234375,2.6044922v2.2250977H160.7324677z"
          />
          <path
            className="st2"
            d="M163.7412567,91.9706116v-4.8295898h0.5429688v4.8295898H163.7412567z"
          />
          <path
            className="st2"
            d="M166.948288,89.1131897l0.4511719,0.2460938c0.3300781,0.1821289,0.5615234,0.3417969,0.6943359,0.4785156
			c0.21875,0.2299805,0.328125,0.5136719,0.328125,0.8510742c0,0.4716797-0.171875,0.8237305-0.5136719,1.0561523
			c-0.2949219,0.2026367-0.6914063,0.3041992-1.1914063,0.3041992c-0.3007813,0-0.6728516-0.0488281-1.1152344-0.1459961v-0.5581055
			c0.421875,0.1665039,0.8144531,0.2495117,1.1777344,0.2495117c0.3076172,0,0.5566406-0.0708008,0.7460938-0.2124023
			c0.1982422-0.1508789,0.2978516-0.3549805,0.2978516-0.6132813c0-0.2421875-0.0830078-0.4453125-0.25-0.6098633
			c-0.09375-0.0888672-0.2783203-0.2109375-0.5546875-0.3657227l-0.4277344-0.2392578
			c-0.3515625-0.1958008-0.5820313-0.3442383-0.6914063-0.4443359c-0.2353516-0.21875-0.3525391-0.487793-0.3525391-0.8066406
			c0-0.4033203,0.1484375-0.7143555,0.4453125-0.9331055c0.2753906-0.2050781,0.6464844-0.3076172,1.1123047-0.3076172
			c0.2666016,0,0.6015625,0.0419922,1.0058594,0.1264648v0.5400391c-0.3398438-0.1411133-0.6914063-0.2119141-1.0556641-0.2119141
			c-0.2763672,0-0.4970703,0.0668945-0.6621094,0.199707c-0.1650391,0.1337891-0.2480469,0.3129883-0.2480469,0.5385742
			c0,0.1870117,0.0869141,0.3579102,0.2597656,0.5126953C166.5049286,88.8544006,166.6865692,88.969635,166.948288,89.1131897z"
          />
          <path
            className="st2"
            d="M170.4600067,91.9706116v-2.2250977l-1.5039063-2.6044922h0.5947266l1.1806641,2.0507813l1.2021484-2.0507813
			h0.59375l-1.5234375,2.6044922v2.2250977H170.4600067z"
          />
          <path
            className="st2"
            d="M174.3105927,91.9706116v-2.2250977l-1.5039063-2.6044922h0.5947266l1.1806641,2.0507813l1.2021484-2.0507813
			h0.59375l-1.5234375,2.6044922v2.2250977H174.3105927z"
          />
          <path
            className="st2"
            d="M178.3652802,89.1131897l0.4511719,0.2460938c0.3300781,0.1821289,0.5615234,0.3417969,0.6943359,0.4785156
			c0.21875,0.2299805,0.328125,0.5136719,0.328125,0.8510742c0,0.4716797-0.171875,0.8237305-0.5136719,1.0561523
			c-0.2949219,0.2026367-0.6914063,0.3041992-1.1914063,0.3041992c-0.3007813,0-0.6728516-0.0488281-1.1152344-0.1459961v-0.5581055
			c0.421875,0.1665039,0.8144531,0.2495117,1.1777344,0.2495117c0.3076172,0,0.5566406-0.0708008,0.7460938-0.2124023
			c0.1982422-0.1508789,0.2978516-0.3549805,0.2978516-0.6132813c0-0.2421875-0.0830078-0.4453125-0.25-0.6098633
			c-0.09375-0.0888672-0.2783203-0.2109375-0.5546875-0.3657227l-0.4277344-0.2392578
			c-0.3515625-0.1958008-0.5820313-0.3442383-0.6914063-0.4443359c-0.2353516-0.21875-0.3525391-0.487793-0.3525391-0.8066406
			c0-0.4033203,0.1484375-0.7143555,0.4453125-0.9331055c0.2753906-0.2050781,0.6464844-0.3076172,1.1123047-0.3076172
			c0.2666016,0,0.6015625,0.0419922,1.0058594,0.1264648v0.5400391c-0.3398438-0.1411133-0.6914063-0.2119141-1.0556641-0.2119141
			c-0.2763672,0-0.4970703,0.0668945-0.6621094,0.199707c-0.1650391,0.1337891-0.2480469,0.3129883-0.2480469,0.5385742
			c0,0.1870117,0.0869141,0.3579102,0.2597656,0.5126953C177.9219208,88.8544006,178.1035614,88.969635,178.3652802,89.1131897z"
          />
        </g>
        <path
          id="plate_00000181078867745294210460000008350353592415156129_"
          onClick={toggleSceneNameFuture}
          className="st2 switch"
          d="M163.5386963,130.6070862h-0.0000305
		c-5.5916138,0-10.1665802-4.5749588-10.1665802-10.1665726V104.951889c0-5.5916214,4.5749664-10.1665802,10.1665955-10.1665802
		h0.0000153c5.5916138,0,10.1665802,4.5749588,10.1665802,10.1665802v15.4886169
		C173.7052765,126.0321274,169.1303101,130.6070862,163.5386963,130.6070862z"
        />

        {isSceneNameFuture ? (
          <g id="switch-down" onClick={toggleSceneNameFuture}>
            <path
              className="st0"
              d="M163.538681,112.5628891c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962631
			c0,4.1248474,3.2714233,7.3962708,7.3962555,7.3962708c3.982605,0,7.3962708-3.2714233,7.3962708-7.3962708
			C170.9349518,115.8343124,167.521286,112.5628891,163.538681,112.5628891z"
            />
          </g>
        ) : (
          <g id="switch-up" onClick={toggleSceneNameFuture}>
            <path
              className="st0"
              d="M163.538681,97.7703552c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962708
			c0,4.1248398,3.2714233,7.3962631,7.3962555,7.3962631c3.982605,0,7.3962555-3.2714233,7.3962555-7.3962631
			C170.9349365,101.0417786,167.521286,97.7703552,163.538681,97.7703552z"
            />
          </g>
        )}
      </g>
      {/* ELEVATOR */}
      <g id="elevator_7_">
        <path
          id="plate_00000034071677713093229840000013259720776655303851_"
          className="st2"
          d="M163.5386963,76.2779083h-0.0000153
		c-5.591629,0-10.1665955-4.5749588-10.1665955-10.1665802V31.728981c0-5.5916195,4.5749664-10.1665821,10.1665955-10.1665821
		h0.0000153c5.5916138,0,10.1665802,4.5749626,10.1665802,10.1665821v34.3823471
		C173.7052765,71.7029419,169.1303101,76.2779083,163.5386963,76.2779083z"
        />
        <g id="_x38__00000142880485554278463060000017701388247961430162_">
          <path
            className="st11"
            d="M161.5068817,51.3485413V46.89151l-0.9609375,0.6484375v-0.6054688l1.046875-0.65625h0.5117188v5.0703125
			H161.5068817z"
          />
          <path
            className="st11"
            d="M164.667038,48.5140686v0.3735352h0.015625c0.2548828-0.3984375,0.6601563-0.5976563,1.2148438-0.5976563
			c0.4638672,0,0.8193359,0.1552734,1.0664063,0.465332c0.2236328,0.2817383,0.3359375,0.6547852,0.3359375,1.1186523
			c0,0.4770508-0.1435547,0.8574219-0.4316406,1.1401367s-0.6591797,0.4243164-1.1152344,0.4243164
			c-0.6845703,0-1.1591797-0.265625-1.421875-0.796875c-0.1923828-0.390625-0.2890625-0.9975586-0.2890625-1.8203125
			c0-0.8125,0.1064453-1.4257813,0.3203125-1.8398438c0.2763672-0.5288086,0.7451172-0.7929688,1.40625-0.7929688
			c0.5,0,0.8789063,0.1489258,1.1367188,0.4467773c0.1875,0.2143555,0.28125,0.4428711,0.28125,0.6860352h-0.6953125
			c0-0.1459961-0.0498047-0.2827148-0.1484375-0.4101563c-0.1357422-0.1743164-0.3349609-0.2617188-0.5976563-0.2617188
			c-0.4013672,0-0.6923828,0.2114258-0.875,0.6347656C164.7344208,47.598053,164.667038,48.0077209,164.667038,48.5140686z
			 M164.760788,49.8641663c0,0.3305664,0.0859375,0.5961914,0.2587891,0.796875
			c0.1796875,0.2109375,0.4248047,0.3164063,0.7353516,0.3164063c0.6162109,0,0.9238281-0.3686523,0.9238281-1.1054688
			c0-0.7524414-0.3134766-1.1289063-0.9394531-1.1289063c-0.3027344,0-0.5410156,0.1035156-0.7158203,0.3105469
			S164.760788,49.5306702,164.760788,49.8641663z"
          />
        </g>
        <g id="elevator-down-btn" onClick={() => changeFloor('down')}>
          <path
            className="st0"
            d="M163.538681,57.76297c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.3962631
			s3.2714233,7.3962631,7.3962555,7.3962631c3.982605,0,7.3962708-3.2714233,7.3962708-7.3962631
			S167.521286,57.76297,163.538681,57.76297z"
          />
          <path
            className="st2"
            d="M168.2324524,63.7368736l0.426712,0.568943l-4.8360138,3.2714233
			c-0.1422424,0.1422348-0.426712,0.1422348-0.5689392,0l-4.8359985-3.2714233l0.426712-0.568943l4.5515289,2.9869576
			c0.1422577,0.1422348,0.2844696,0.1422348,0.426712,0L168.2324524,63.7368736z"
          />
        </g>
        <g id="elevator-up-btn" onClick={() => changeFloor('up')}>
          <path
            className="st0"
            d="M163.538681,24.9413624c-4.1248322,0-7.3962555,3.2714233-7.3962555,7.396265
			c0,4.124836,3.2714233,7.3962631,7.3962555,7.3962631c3.982605,0,7.3962708-3.2714233,7.3962708-7.3962631
			S167.521286,24.9413624,163.538681,24.9413624z"
          />
          <path
            className="st2"
            d="M168.2324524,33.7599831l0.426712-0.568943l-4.8360138-3.2714214
			c-0.1422424-0.1422367-0.426712-0.1422367-0.5689392,0l-4.8359985,3.2714214l0.426712,0.568943l4.5515289-2.9869518
			c0.1422577-0.1422367,0.2844696-0.1422367,0.426712,0L168.2324524,33.7599831z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  119: [97.1460342, 18.0990105],
}

const SVG = styled.svg`
  .st0 {
    fill: #d5d4cf;
  }
  .st1 {
    fill: var(--available-color);
  }
  .st2 {
    fill: #3f3f3c;
  }
  .st3 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8503937;
    stroke-miterlimit: 10;
  }
  .st4 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.2834646;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 0.8504;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: #e5e5e5;
    stroke: #3d3d3b;
    stroke-width: 0.2835;
    stroke-miterlimit: 10;
  }
  .st7 {
    fill: none;
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }
  .st8 {
    fill: none;
    stroke: #3d3d3b;
    stroke-width: 1.4173;
    stroke-miterlimit: 10;
  }
  .st9 {
    fill: url(#cone_00000000924872679882030950000005328143078047156628_);
  }
  .st10 {
    fill: #c65805;
  }
  .st11 {
    fill: #e7e7e7;
  }

  #switch-up:hover,
  #switch-down:hover {
    cursor: pointer;

    .st0 {
      transition: fill 0.3s ease;
      fill: var(--secondary-color);
    }
  }

  #elevator-down-btn:hover {
    cursor: pointer;

    .st0 {
      transition: fill 0.3s ease;
      fill: var(--secondary-color);
    }

    .st2 {
      transition: fill 0.3s ease;
      fill: var(--light);
    }
  }

  #elevator-up-btn {
    cursor: not-allowed;

    .st0 {
      fill: var(--color-sold);
    }

    .st8 {
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--primary-color);
  }

  .active {
    .st2 {
      fill: var(--primary-color);
    }
  }

  .on-hover {
    transition: fill 0.3s ease;
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text {
    fill: #ffffff;
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white .st2 {
    fill: #fff;
    cursor: pointer;
  }

  .text-white-sold .st2 {
    fill: #fff;
    pointer-events: none;
  }

  .circle-white {
    fill: #fff;
  }

  .reserved {
    fill: yellow;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
