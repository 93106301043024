const FLOOR_2_DATA = [
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 1,
    sceneName: 'scene_A01_02krs__lansi_0000',
    sceneName_future: 'scene_A01_02krs__lansi_0000',
    name: 'A1',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      3: 8,
      4: 15,
      5: 22,
      6: 29,
      7: 36,
      8: 42,
      9: 48,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 3,
    sceneName: 'scene_A03_02krs__etela_0000',
    sceneName_future: 'scene_A03_02krs__etela_0000',
    name: 'A3',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      3: 10,
      4: 17,
      5: 24,
      6: 31,
      7: 38,
      8: 44,
      9: 50,
      10: 55,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 4,
    sceneName: 'scene_A04_02krs__etela_0000',
    sceneName_future: 'scene_A04_02krs__etela_0000',
    name: 'A4',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      3: 11,
      4: 18,
      5: 25,
      6: 32,
      7: 39,
      8: 45,
      9: 51,
      10: 56,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 2,
    building: 'A',
    apartmentNumber: 5,
    sceneName: 'scene_A05_02krs__ita_0000',
    sceneName_future: 'scene_A05_02krs__ita_0000',
    name: 'A5',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      3: 12,
      4: 19,
      5: 26,
      6: 33,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
]

export default FLOOR_2_DATA
