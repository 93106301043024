const FLOOR_4_DATA = [
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 13,
    sceneName: 'scene_A13_04krs__ita_0000',
    sceneName_future: 'scene_A13_04krs__ita_0000',
    name: 'A13',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      5: 20,
      6: 27,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 14,
    sceneName: 'scene_A14_04krs__lansi_0000',
    sceneName_future: 'scene_A14_04krs__lansi_0000',
    name: 'A14',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 7,
      5: 21,
      6: 28,
      7: 35,
      8: 41,
      9: 47,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 15,
    sceneName: 'scene_A15_04krs__lansi_0000',
    sceneName_future: 'scene_A15_04krs__lansi_0000',
    name: 'A15',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 8,
      5: 22,
      6: 29,
      7: 36,
      8: 42,
      9: 48,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 17,
    sceneName: 'scene_A17_04krs__etela_0000',
    sceneName_future: 'scene_A17_04krs__etela_0000',
    name: 'A17',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 3,
      3: 10,
      5: 24,
      6: 31,
      7: 38,
      8: 44,
      9: 50,
      10: 55,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 18,
    sceneName: 'scene_A18_04krs__etela_0000',
    sceneName_future: 'scene_A18_04krs__etela_0000',
    name: 'A18',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 4,
      3: 11,
      5: 25,
      6: 32,
      7: 39,
      8: 45,
      9: 51,
      10: 56,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 4,
    building: 'A',
    apartmentNumber: 19,
    sceneName: 'scene_A19_04krs__ita_0000',
    sceneName_future: 'scene_A19_04krs__ita_0000',
    name: 'A19',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 12,
      5: 26,
      6: 33,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
]

export default FLOOR_4_DATA
