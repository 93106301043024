import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor6({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
  apartments,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="floor-6"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 195 120"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="205.3938"
          y1="235.8948"
          x2="205.3938"
          y2="190.9848"
          gradientTransform="matrix(1, 0, 0, -1, -164.9032, 264.9841)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="210.8696"
          y1="157.6703"
          x2="210.8696"
          y2="112.7603"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="223.9038"
          y1="157.6703"
          x2="223.9038"
          y2="112.7603"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="254.1763"
          y1="165.1098"
          x2="254.1763"
          y2="120.1998"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="309.078"
          y1="206.2305"
          x2="309.078"
          y2="161.3205"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="309.078"
          y1="218.8639"
          x2="309.078"
          y2="173.9539"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="217.6874"
          y1="240.0823"
          x2="217.6874"
          y2="195.1723"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="195" height="120" />
        </g>
      </g>
      {/* APARTMENTS */}
      <g id="apartments">
        <g
          onClick={() => changeView(6, 27)}
          onMouseOver={() => setElementOnHover('A27')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A27"
            className={`cls-2 ${
              sceneName === 'scene_A27_06krs__ita_0000'
                ? 'active-apt'
                : elementOnHover === 'A27'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A27') === 'sold' ? 'bg-gray' : ''
            }`}
            points="32.886 22.01 14.364 28.237 14.364 62.972 51.669 62.972 51.669 48.903 45.175 29.509 47.997 28.566 44.35 17.585 33.222 21.228 32.886 22.01"
          />
        </g>
        <g
          onClick={() => changeView(6, 28)}
          onMouseOver={() => setElementOnHover('A28')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A28"
            className={`cls-2 ${
              sceneName === 'scene_A28_06krs__lansi_0000'
                ? 'active-apt'
                : elementOnHover === 'A28'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A28') === 'sold' ? 'bg-gray' : ''
            }`}
            points="14.364 106.985 38.017 106.985 38.64 107.611 53.969 107.611 53.969 96.575 55.349 96.575 55.349 72.011 42.194 72.011 42.194 62.97 14.364 62.97 14.364 106.985"
          />
        </g>
        <g
          onClick={() => changeView(6, 29)}
          onMouseOver={() => setElementOnHover('A29')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A29"
            className={`cls-2 ${
              sceneName === 'scene_A29_06krs__lansi_0000'
                ? 'active-apt'
                : elementOnHover === 'A29'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A29') === 'sold' ? 'bg-gray' : ''
            }`}
            points="83.031 72.135 55.349 72.135 55.349 96.575 53.969 96.575 53.969 107.611 83.031 107.611 83.031 72.135"
          />
        </g>
        <polygon
          id="A9"
          className="cls-2"
          x="83.0308"
          y="72.1354"
          width="22.1929"
          height="34.8494"
        />
        <g
          onClick={() => changeView(6, 31)}
          onMouseOver={() => setElementOnHover('A31')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A31"
            className={`cls-2 ${
              sceneName === 'scene_A31_06krs__etela_0000'
                ? 'active-apt'
                : elementOnHover === 'A31'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A31') === 'sold' ? 'bg-gray' : ''
            }`}
            points="105.221 106.985 105.221 49.79 138.822 49.79 138.822 46.727 149.972 46.727 149.972 63.224 149.193 63.224 149.193 83.019 105.221 106.985"
          />
        </g>
        <g
          onClick={() => changeView(6, 32)}
          onMouseOver={() => setElementOnHover('A32')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A32"
            className={`cls-2 ${
              sceneName === 'scene_A32_06krs__etela_0000'
                ? 'active-apt'
                : elementOnHover === 'A32'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A32') === 'sold' ? 'bg-gray' : ''
            }`}
            points="120.631 49.79 120.631 33.916 105.221 33.916 105.221 13.015 149.403 13.015 149.403 35.832 149.972 35.832 149.972 46.727 138.822 46.727 138.822 49.79 120.631 49.79"
          />
        </g>
        <g
          onClick={() => changeView(6, 33)}
          onMouseOver={() => setElementOnHover('A33')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A33"
            className={`cls-2 ${
              sceneName === 'scene_A33_06krs__ita_0000'
                ? 'active-apt'
                : elementOnHover === 'A33'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A33') === 'sold' ? 'bg-gray' : ''
            }`}
            points="44.35 17.585 47.997 28.566 45.175 29.509 51.669 48.903 95.567 48.903 95.567 12.859 60.785 13.018 60.089 12.432 44.35 17.585"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polygon
          className="cls-3"
          points="14.319 106.985 38.018 106.985 38.018 96.458 65.74 96.458 65.74 106.985 105.863 106.985 149.403 82.845 149.403 63.224 138.876 63.224 138.876 35.832 149.403 35.832 149.403 13.015 60.725 13.015 60.725 24.314 36.474 32.415 33.057 21.889 14.319 28.282 14.319 106.985"
        />
        <polygon
          className="cls-4"
          points="42.095 71.977 105.221 71.977 105.221 13.015 95.567 13.015 95.567 62.972 51.286 62.972 42.095 62.972 42.095 71.977"
        />
        <polyline
          className="cls-4"
          points="95.567 48.862 51.719 48.862 51.719 62.972"
        />
        <line
          className="cls-4"
          x1="45.1749"
          y1="29.5087"
          x2="51.7192"
          y2="48.8618"
        />
        <line
          className="cls-4"
          x1="42.0951"
          y1="62.9723"
          x2="14.3186"
          y2="62.9723"
        />
        <line
          className="cls-4"
          x1="55.3702"
          y1="71.9771"
          x2="55.3702"
          y2="96.458"
        />
        <line
          className="cls-4"
          x1="82.9947"
          y1="71.9771"
          x2="82.9947"
          y2="106.9847"
        />
        <line
          className="cls-4"
          x1="105.2213"
          y1="71.9771"
          x2="105.2213"
          y2="106.9847"
        />
        <polyline
          className="cls-4"
          points="105.221 49.79 120.631 49.79 138.876 49.79"
        />
        <polyline
          className="cls-4"
          points="105.221 33.916 120.631 33.916 120.631 49.79"
        />
        <polyline
          className="cls-5"
          points="149.972 35.832 149.972 46.873 149.972 63.224"
        />
        <line
          className="cls-5"
          x1="138.8764"
          y1="46.7274"
          x2="149.9715"
          y2="46.7274"
        />
        <line
          className="cls-5"
          x1="65.7401"
          y1="107.5804"
          x2="38.0177"
          y2="107.5804"
        />
        <line
          className="cls-5"
          x1="53.9688"
          y1="96.458"
          x2="53.9688"
          y2="107.5804"
        />
        <line
          className="cls-5"
          x1="33.2215"
          y1="21.2281"
          x2="60.0891"
          y2="12.4317"
        />
        <line
          className="cls-5"
          x1="47.9965"
          y1="28.566"
          x2="44.3503"
          y2="17.5845"
        />
      </g>
      {/* APARTMENTS NUMBERS */}
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`
            ${
              sceneName === 'scene_A27_06krs__ita_0000'
                ? 'text-white'
                : elementOnHover === 'A27'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A27') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(6, 27)}
          onMouseOver={() => setElementOnHover('A27')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M32.1965,45.5h-.8916l-.3545-.9219h-1.622l-.335.9219h-.8691l1.58-4.0586h.8667Zm-1.5088-1.6055-.559-1.5058L29.58,43.8949Z"
          />
          <path
            className="cls-6"
            d="M35.3694,44.7777V45.5H32.6428a1.88,1.88,0,0,1,.2657-.7764,5.1647,5.1647,0,0,1,.875-.9726,5.5685,5.5685,0,0,0,.645-.6651.8556.8556,0,0,0,.1606-.4756.5408.5408,0,0,0-.14-.4.5234.5234,0,0,0-.3863-.14.5177.5177,0,0,0-.3872.1465.7526.7526,0,0,0-.166.4873l-.7754-.0772a1.2708,1.2708,0,0,1,.4346-.9218,1.46,1.46,0,0,1,.9136-.28,1.32,1.32,0,0,1,.9438.3242,1.0617,1.0617,0,0,1,.3433.8057,1.401,1.401,0,0,1-.0982.5215,2.1212,2.1212,0,0,1-.3115.52,4.9536,4.9536,0,0,1-.5093.5176q-.3684.3369-.4663.4482a1.3516,1.3516,0,0,0-.1591.2158Z"
          />
          <path
            className="cls-6"
            d="M36.179,42.22v-.7226h2.66v.5644a4.1983,4.1983,0,0,0-.67.9307,5.4552,5.4552,0,0,0-.519,1.2881,4.8094,4.8094,0,0,0-.1758,1.22h-.75a5.2073,5.2073,0,0,1,.3476-1.7158,5.6971,5.6971,0,0,1,.876-1.5645Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A28_06krs__lansi_0000'
                ? 'text-white'
                : elementOnHover === 'A28'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A28') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(6, 28)}
          onMouseOver={() => setElementOnHover('A28')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M32.1975,86.4691h-.8916l-.3545-.9218H29.3293l-.3349.9218h-.8692l1.5806-4.0585h.8667Zm-1.5088-1.6054L30.13,83.3578l-.5483,1.5059Z"
          />
          <path
            className="cls-6"
            d="M35.37,85.7465v.7226H32.6438a1.8808,1.8808,0,0,1,.2656-.7763,5.1687,5.1687,0,0,1,.875-.9727,5.5456,5.5456,0,0,0,.645-.665A.8555.8555,0,0,0,34.59,83.58a.5412.5412,0,0,0-.14-.4.5233.5233,0,0,0-.3863-.14.518.518,0,0,0-.3872.1464.753.753,0,0,0-.166.4873l-.7754-.0771a1.2707,1.2707,0,0,1,.4346-.9219,1.46,1.46,0,0,1,.9136-.28,1.32,1.32,0,0,1,.9438.3242,1.0615,1.0615,0,0,1,.3433.8056,1.4,1.4,0,0,1-.0982.5215,2.1175,2.1175,0,0,1-.3115.52,4.9541,4.9541,0,0,1-.5093.5176q-.3684.3369-.4663.4483a1.3587,1.3587,0,0,0-.1592.2158Z"
          />
          <path
            className="cls-6"
            d="M36.8474,84.28a.8725.8725,0,0,1-.5762-.8389.9831.9831,0,0,1,.3169-.75,1.512,1.512,0,0,1,1.7994,0,.9772.9772,0,0,1,.32.75.8831.8831,0,0,1-.1465.502.9309.9309,0,0,1-.4126.3369,1.0871,1.0871,0,0,1,.5137.3955,1.0488,1.0488,0,0,1,.1758.6006,1.2244,1.2244,0,0,1-.3584.914,1.3041,1.3041,0,0,1-.9537.3516,1.4432,1.4432,0,0,1-.9218-.291,1.1332,1.1332,0,0,1-.4346-.9414,1.1622,1.1622,0,0,1,.1631-.6045A1.05,1.05,0,0,1,36.8474,84.28Zm.0884.9551a.7149.7149,0,0,0,.1645.5009.5338.5338,0,0,0,.4112.18.5175.5175,0,0,0,.3989-.1728.7183.7183,0,0,0,.1577-.5.6477.6477,0,0,0-.1606-.458.5314.5314,0,0,0-.4073-.1729.4871.4871,0,0,0-.4248.1963A.7213.7213,0,0,0,36.9358,85.2348Zm.0718-1.7383a.487.487,0,0,0,.1318.3623.4744.4744,0,0,0,.35.13.4836.4836,0,0,0,.354-.1309.49.49,0,0,0,.1333-.3642.4728.4728,0,0,0-.1318-.3506.4673.4673,0,0,0-.3472-.1309.483.483,0,0,0-.3574.1328A.4738.4738,0,0,0,37.0076,83.4965Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A29_06krs__lansi_0000'
                ? 'text-white'
                : elementOnHover === 'A29'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A29') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(6, 29)}
          onMouseOver={() => setElementOnHover('A29')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M69.4485,86.47h-.8916l-.3545-.9219H66.58l-.3349.9219h-.8692l1.5806-4.0586h.8667ZM67.94,84.8647l-.5591-1.5059-.5483,1.5059Z"
          />
          <path
            className="cls-6"
            d="M72.6213,85.7475V86.47H69.8948a1.8793,1.8793,0,0,1,.2656-.7763,5.1687,5.1687,0,0,1,.875-.9727,5.5541,5.5541,0,0,0,.645-.665.8555.8555,0,0,0,.1607-.4756.5407.5407,0,0,0-.14-.4.5941.5941,0,0,0-.7734.0068.7525.7525,0,0,0-.166.4873l-.7754-.0771a1.2707,1.2707,0,0,1,.4346-.9219,1.4594,1.4594,0,0,1,.9135-.28,1.32,1.32,0,0,1,.9439.3242,1.0617,1.0617,0,0,1,.3432.8057,1.4026,1.4026,0,0,1-.0981.5215,2.1194,2.1194,0,0,1-.3115.5195,4.9641,4.9641,0,0,1-.5093.5176q-.3684.3369-.4663.4482a1.3576,1.3576,0,0,0-.1592.2159Z"
          />
          <path
            className="cls-6"
            d="M73.4475,85.5316l.7529-.083a.5465.5465,0,0,0,.1441.3409.43.43,0,0,0,.3071.11.4988.4988,0,0,0,.41-.2217,1.8859,1.8859,0,0,0,.2163-.9189.9282.9282,0,0,1-.7339.3408,1.0938,1.0938,0,0,1-.8262-.37,1.3467,1.3467,0,0,1-.3471-.9619,1.3677,1.3677,0,0,1,.3667-.9951,1.2444,1.2444,0,0,1,.934-.378,1.2575,1.2575,0,0,1,1.0132.4776,2.47,2.47,0,0,1,.396,1.5713,2.4962,2.4962,0,0,1-.4126,1.6054,1.3326,1.3326,0,0,1-1.0742.4932,1.1364,1.1364,0,0,1-.77-.2539A1.2255,1.2255,0,0,1,73.4475,85.5316Zm1.7608-1.6992a.8911.8911,0,0,0-.1729-.5849.5131.5131,0,0,0-.4-.2071.4492.4492,0,0,0-.3584.17.8675.8675,0,0,0-.1426.5576.8824.8824,0,0,0,.1553.5771.4946.4946,0,0,0,.7651.0069A.7693.7693,0,0,0,75.2083,83.8324Z"
          />
        </g>
        <g>
          <path
            className="cls-6"
            d="M92.9075,86.4691h-.8916l-.3545-.9218H90.0393l-.335.9218h-.8691l1.5806-4.0585h.8667Zm-1.5088-1.6054L90.84,83.3578l-.5483,1.5059Z"
          />
          <path
            className="cls-6"
            d="M93.426,85.392l.753-.0908a.71.71,0,0,0,.1933.4394.5311.5311,0,0,0,.3823.1524A.5232.5232,0,0,0,95.16,85.71a.7066.7066,0,0,0,.1651-.4922.66.66,0,0,0-.1578-.4649.5016.5016,0,0,0-.3852-.1718,1.3816,1.3816,0,0,0-.3569.0576l.0859-.6338a.68.68,0,0,0,.4814-.1367.4877.4877,0,0,0,.1665-.3868.42.42,0,0,0-.4458-.4482.468.468,0,0,0-.3408.1387.6489.6489,0,0,0-.1714.4043l-.7168-.1221a1.6006,1.6006,0,0,1,.2256-.5879,1.0145,1.0145,0,0,1,.42-.3467,1.415,1.415,0,0,1,.605-.1259,1.2048,1.2048,0,0,1,.919.3662.9245.9245,0,0,1-.2989,1.5273.9466.9466,0,0,1,.5576.335.9707.9707,0,0,1,.209.6289,1.2184,1.2184,0,0,1-.39.91,1.4563,1.4563,0,0,1-1.8853.06A1.2431,1.2431,0,0,1,93.426,85.392Z"
          />
          <path
            className="cls-6"
            d="M98.2048,82.394a1.11,1.11,0,0,1,.9219.4208,3.6416,3.6416,0,0,1-.0029,3.3086,1.1076,1.1076,0,0,1-.919.4151,1.1633,1.1633,0,0,1-.955-.4551,3.7132,3.7132,0,0,1,.0361-3.2734A1.106,1.106,0,0,1,98.2048,82.394Zm0,.6455a.39.39,0,0,0-.2519.09.6007.6007,0,0,0-.1714.3223,4.4365,4.4365,0,0,0-.0806,1.0166,4.3808,4.3808,0,0,0,.0723.98.693.693,0,0,0,.1811.3565.4.4,0,0,0,.5025-.002.6039.6039,0,0,0,.1719-.3222,6.5958,6.5958,0,0,0,.0083-1.9942.7.7,0,0,0-.1817-.3574A.3842.3842,0,0,0,98.2048,83.04Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A31_06krs__etela_0000'
                ? 'text-white'
                : elementOnHover === 'A31'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A31') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(6, 31)}
          onMouseOver={() => setElementOnHover('A31')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M127.5955,74.5277h-.8916l-.3545-.9218h-1.6221l-.335.9218h-.8691l1.5811-4.0586h.8662Zm-1.5088-1.6054-.5586-1.5059-.5488,1.5059Z"
          />
          <path
            className="cls-6"
            d="M128.114,73.4506l.7529-.0908a.7161.7161,0,0,0,.1934.4394.5322.5322,0,0,0,.3828.1524.5249.5249,0,0,0,.4053-.1826.7089.7089,0,0,0,.165-.4922.6576.6576,0,0,0-.1582-.4649.5008.5008,0,0,0-.3847-.1719,1.3869,1.3869,0,0,0-.3575.0577l.086-.6338a.68.68,0,0,0,.4814-.1367.4865.4865,0,0,0,.167-.3868.42.42,0,0,0-.4463-.4482.4687.4687,0,0,0-.3408.1387.6454.6454,0,0,0-.1709.4043l-.7168-.1221a1.6006,1.6006,0,0,1,.2256-.5879,1.0129,1.0129,0,0,1,.42-.3467,1.4173,1.4173,0,0,1,.6055-.126,1.2045,1.2045,0,0,1,.9189.3663.9244.9244,0,0,1-.2988,1.5273.9484.9484,0,0,1,.5576.335.9707.9707,0,0,1,.209.6289,1.2172,1.2172,0,0,1-.3906.91,1.4556,1.4556,0,0,1-1.8848.06A1.2436,1.2436,0,0,1,128.114,73.4506Z"
          />
          <path
            className="cls-6"
            d="M133.5681,74.5277h-.7773V71.5961a2.6916,2.6916,0,0,1-1.0049.59V71.48a2.2358,2.2358,0,0,0,.6611-.3779,1.4345,1.4345,0,0,0,.49-.65h.6308Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A32_06krs__etela_0000'
                ? 'text-white'
                : elementOnHover === 'A32'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A32') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(6, 32)}
          onMouseOver={() => setElementOnHover('A32')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M127.5964,30.5126h-.8916l-.3545-.9219h-1.622l-.335.9219h-.8691l1.58-4.0586h.8671Zm-1.5087-1.6055-.56-1.5058-.5479,1.5058Z"
          />
          <path
            className="cls-6"
            d="M128.115,29.4355l.7529-.0909a.7055.7055,0,0,0,.1934.4395.53.53,0,0,0,.3818.1523.5216.5216,0,0,0,.4053-.1826.7045.7045,0,0,0,.165-.4922.6613.6613,0,0,0-.1572-.4648.5027.5027,0,0,0-.3857-.1719,1.38,1.38,0,0,0-.3565.0576l.086-.6338a.68.68,0,0,0,.4814-.1367.4888.4888,0,0,0,.166-.3867.4421.4421,0,0,0-.1221-.3262.4366.4366,0,0,0-.3232-.122.4673.4673,0,0,0-.3408.1386.6511.6511,0,0,0-.1719.4043l-.7168-.122a1.5992,1.5992,0,0,1,.2256-.5879,1.016,1.016,0,0,1,.4209-.3467,1.4124,1.4124,0,0,1,.6045-.126,1.2048,1.2048,0,0,1,.9189.3662.9245.9245,0,0,1-.2988,1.5274.9458.9458,0,0,1,.5576.3349.9709.9709,0,0,1,.209.6289,1.22,1.22,0,0,1-.39.91,1.4573,1.4573,0,0,1-1.8858.06A1.2428,1.2428,0,0,1,128.115,29.4355Z"
          />
          <path
            className="cls-6"
            d="M134.2058,29.79v.7227h-2.7265a1.8717,1.8717,0,0,1,.2656-.7764,5.1467,5.1467,0,0,1,.875-.9726,5.49,5.49,0,0,0,.6445-.6651.8525.8525,0,0,0,.1611-.4755.5387.5387,0,0,0-.1406-.4.5941.5941,0,0,0-.7734.0068.755.755,0,0,0-.166.4873L131.57,27.64a1.2741,1.2741,0,0,1,.4345-.9219,1.4624,1.4624,0,0,1,.9141-.28,1.3192,1.3192,0,0,1,.9434.3242,1.06,1.06,0,0,1,.3437.8057,1.4034,1.4034,0,0,1-.0986.5215,2.1087,2.1087,0,0,1-.3115.52,4.9988,4.9988,0,0,1-.5088.5176q-.3692.3369-.4668.4482a1.3776,1.3776,0,0,0-.1592.2158Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A33_06krs__ita_0000'
                ? 'text-white'
                : elementOnHover === 'A33'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A33') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(6, 33)}
          onMouseOver={() => setElementOnHover('A33')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M76.0457,30.5126h-.8916L74.8,29.5907H73.1775l-.335.9219h-.8691L73.554,26.454h.8667Zm-1.5088-1.6055-.5591-1.5058-.5484,1.5058Z"
          />
          <path
            className="cls-6"
            d="M76.5642,29.4355l.7529-.0909a.7109.7109,0,0,0,.1934.4395.531.531,0,0,0,.3823.1523.5231.5231,0,0,0,.4053-.1826.7064.7064,0,0,0,.165-.4922.6592.6592,0,0,0-.1577-.4648.5015.5015,0,0,0-.3852-.1719,1.3829,1.3829,0,0,0-.357.0576l.086-.6338a.68.68,0,0,0,.4814-.1367.4876.4876,0,0,0,.1665-.3867.42.42,0,0,0-.4458-.4482.4682.4682,0,0,0-.3408.1386.6486.6486,0,0,0-.1714.4043l-.7168-.122a1.5992,1.5992,0,0,1,.2256-.5879,1.0145,1.0145,0,0,1,.42-.3467,1.415,1.415,0,0,1,.605-.126,1.2048,1.2048,0,0,1,.9189.3662.9245.9245,0,0,1-.2988,1.5274.9473.9473,0,0,1,.5576.3349.9709.9709,0,0,1,.209.6289,1.2186,1.2186,0,0,1-.39.91,1.4566,1.4566,0,0,1-1.8853.06A1.2433,1.2433,0,0,1,76.5642,29.4355Z"
          />
          <path
            className="cls-6"
            d="M80.0007,29.4355l.753-.0909a.71.71,0,0,0,.1933.4395.531.531,0,0,0,.3823.1523.5229.5229,0,0,0,.4053-.1826.7065.7065,0,0,0,.1651-.4922.6592.6592,0,0,0-.1578-.4648.5014.5014,0,0,0-.3852-.1719A1.3816,1.3816,0,0,0,81,28.6825l.0859-.6338a.68.68,0,0,0,.4814-.1367.4876.4876,0,0,0,.1665-.3867.42.42,0,0,0-.4458-.4482.4683.4683,0,0,0-.3408.1386.6492.6492,0,0,0-.1714.4043l-.7168-.122a1.5992,1.5992,0,0,1,.2256-.5879,1.0151,1.0151,0,0,1,.42-.3467,1.415,1.415,0,0,1,.605-.126,1.205,1.205,0,0,1,.919.3662A.9246.9246,0,0,1,81.93,28.331a.9476.9476,0,0,1,.5577.3349.9714.9714,0,0,1,.2089.6289,1.2182,1.2182,0,0,1-.39.91,1.4566,1.4566,0,0,1-1.8853.06A1.2433,1.2433,0,0,1,80.0007,29.4355Z"
          />
        </g>
      </g>
      {/* CONES */}
      <g id="view">
        {apartmentNumber === 27 ? (
          <g id="A27-3" data-name="A27">
            <path
              id="cone"
              className="cls-7"
              d="M43.8656,29.1093a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location"
              className={`cls-8 ${
                sceneName === 'scene_A27_06krs__ita_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A27'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 28 ? (
          <g id="A28-3" data-name="A28">
            <path
              id="cone-2"
              data-name="cone"
              className="cls-9"
              d="M49.3414,107.3338a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-2"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A28_06krs__lansi_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A28'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 29 ? (
          <g id="A29-3" data-name="A29">
            <path
              id="cone-3"
              data-name="cone"
              className="cls-10"
              d="M62.3756,107.3338a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-3"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A29_06krs__lansi_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A29'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 30 ? (
          <g id="A16-3" data-name="A16">
            <path
              id="cone-4"
              data-name="cone"
              className="cls-11"
              d="M92.6481,99.8943a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-4"
              data-name="location"
              className="cls-8"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 31 ? (
          <g id="A31-3" data-name="A31">
            <path
              id="cone-5"
              data-name="cone"
              className="cls-12"
              d="M147.55,58.7736a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-5"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A31_06krs__etela_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A31'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 32 ? (
          <g id="A32-3" data-name="A32">
            <path
              id="cone-6"
              data-name="cone"
              className="cls-13"
              d="M147.55,46.14a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6L122.28,91.03h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-6"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A32_06krs__etela_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A32'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 33 ? (
          <g id="A33-3" data-name="A33">
            <path
              id="cone-7"
              data-name="cone"
              className="cls-14"
              d="M56.1592,24.9218a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-7"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A33_06krs__ita_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A33'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      {/* ELEVATOR */}
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-6"
          x="162.4275"
          y="33.9157"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_8" data-name="8">
          <path
            className="cls-15"
            d="M174.4285,59.3773l-1.0625.1172a.7764.7764,0,0,0-.2032-.4843.5936.5936,0,0,0-.4257-.1563.72.72,0,0,0-.5879.3125,2.64,2.64,0,0,0-.3028,1.3008,1.2853,1.2853,0,0,1,1.02-.4844,1.5586,1.5586,0,0,1,1.1777.5235,1.8976,1.8976,0,0,1,.49,1.3515,1.9372,1.9372,0,0,1-.5156,1.41,1.8779,1.8779,0,0,1-2.75-.1426,3.4681,3.4681,0,0,1-.5586-2.209,3.5354,3.5354,0,0,1,.5821-2.27,1.88,1.88,0,0,1,1.5117-.6953,1.6067,1.6067,0,0,1,1.08.3652A1.7153,1.7153,0,0,1,174.4285,59.3773ZM171.94,61.7719a1.2416,1.2416,0,0,0,.2461.8262.723.723,0,0,0,.5625.291.64.64,0,0,0,.5078-.2383,1.1975,1.1975,0,0,0,.2031-.7813,1.2614,1.2614,0,0,0-.2187-.8183.7035.7035,0,0,0-1.0821-.0117A1.081,1.081,0,0,0,171.94,61.7719Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.5941,70.1163a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,1,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M177.2879,76.09l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715L167.9,76.09l4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.5941,37.2947a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,1,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M177.2879,46.1133l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  27: [41.0956, 24.5093],
  28: [46.5714, 102.7338],
  29: [59.6056, 102.7338],
  30: [89.8781, 95.2943],
  31: [144.7798, 54.1736],
  32: [144.7798, 41.5402],
  33: [53.3892, 20.3218],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }

  .cls-2,
  .cls-5 {
    fill: #e5e5e5;
  }

  .cls-3,
  .cls-4 {
    fill: none;
  }

  .cls-3,
  .cls-4,
  .cls-5 {
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }

  .cls-3 {
    stroke-width: 1.4173px;
  }

  .cls-4 {
    stroke-width: 0.8504px;
  }

  .cls-5 {
    stroke-width: 0.2835px;
  }

  .cls-6 {
    fill: #3f3f3c;
  }

  .cls-7 {
    fill: url(#linear-gradient);
  }

  .cls-8 {
    fill: #c65805;
  }

  .cls-9 {
    fill: url(#linear-gradient-2);
  }

  .cls-10 {
    fill: url(#linear-gradient-3);
  }

  .cls-11 {
    fill: url(#linear-gradient-4);
  }

  .cls-12 {
    fill: url(#linear-gradient-5);
  }

  .cls-13 {
    fill: url(#linear-gradient-6);
  }

  .cls-14 {
    fill: url(#linear-gradient-7);
  }

  .cls-15 {
    fill: #e7e7e7;
  }

  #switch-up:hover,
  #switch-down:hover {
    cursor: pointer;

    .st0 {
      transition: fill 0.3s ease;
      fill: var(--secondary-color);
    }
  }

  #up:hover,
  #down:hover {
    cursor: pointer;

    .cls-1 {
      transition: fill 0.3s ease;
      fill: var(--secondary-color);
    }

    .cls-6 {
      transition: fill 0.3s ease;
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--primary-color);
  }

  .active {
    .st8 {
      fill: var(--primary-color);
    }
  }

  .on-hover {
    transition: fill 0.3s ease;
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text {
    fill: #ffffff;
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white .cls-6 {
    fill: #fff;
    cursor: pointer;
  }

  .text-white-sold .cls-6 {
    fill: #fff;
    cursor: pointer;
  }

  .circle-white {
    fill: #fff;
  }

  .reserved {
    fill: yellow;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
