const FLOOR_9_DATA = [
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 46,
    sceneName: 'scene_A46_09krs__ita_0000',
    sceneName_future: 'scene_A46_09krs__ita_0000',
    name: 'A46',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      7: 34,
      8: 40,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 47,
    sceneName: 'scene_A47_09krs__lansi_0000',
    sceneName_future: 'scene_A47_09krs__lansi_0000',
    name: 'A47',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 7,
      4: 14,
      5: 21,
      6: 28,
      7: 35,
      8: 41,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 48,
    sceneName: 'scene_A48_09krs__lansi_0000',
    sceneName_future: 'scene_A48_09krs__lansi_0000',
    name: 'A48',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 8,
      4: 15,
      5: 22,
      6: 29,
      7: 36,
      8: 42,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 50,
    sceneName: 'scene_A50_09krs__etela_0000',
    sceneName_future: 'scene_A50_09krs__etela_0000',
    name: 'A50',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 3,
      3: 10,
      4: 17,
      5: 24,
      6: 31,
      7: 38,
      8: 44,
      10: 55,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 9,
    building: 'A',
    apartmentNumber: 51,
    sceneName: 'scene_A51_09krs__etela_0000',
    sceneName_future: 'scene_A51_09krs__etela_0000',
    name: 'A51',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 4,
      3: 11,
      4: 18,
      5: 25,
      6: 32,
      7: 39,
      8: 45,
      10: 56,
      11: 60,
      12: 64,
    },
  },
]

export default FLOOR_9_DATA
