const FLOOR_13_DATA = [
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 94,
    sceneName: 'scene_A94_13krs_6_present',
    sceneName_future: 'scene_A94_13krs_6_future',
    name: 'A94',
    lookAt: '-181, 0, 140',
    face: 'south',

    relatedApartments: {
      16: 119,
      15: 108,
      14: 101,
      12: 87,
      11: 80,
      10: 79,
      9: 64,
      8: 63,
      7: 46,
      6: 45,
      5: 28,
      4: 19,
      3: 10,
      2: 9,
    },
  },
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 96,
    sceneName: 'scene_A96_13krs_3_present',
    sceneName_future: 'scene_A96_13krs_3_future',
    name: 'A96',
    lookAt: '-92, 0, 140',
    face: 'west',

    relatedApartments: {
      16: 119,
      15: 110,
      14: 103,
      12: 89,
      11: 82,
      10: 79,
      9: 66,
      8: 60,
      7: 51,
      6: 42,
      5: 33,
      4: 24,
      3: 15,
      2: 6,
    },
  },
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 98,
    sceneName: 'scene_A98_13krs_9_present',
    sceneName_future: 'scene_A98_13krs_9_future',
    name: 'A98',
    lookAt: '-2, 0, 140',
    face: 'north',

    relatedApartments: {
      16: 119,
      15: 112,
      14: 105,
      12: 91,
      11: 84,
      10: 77,
      9: 70,
      8: 61,
      7: 52,
      6: 43,
      5: 34,
      4: 25,
      3: 16,
      2: 7,
    },
  },
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 99,
    sceneName: 'scene_A99_13krs_8_present',
    sceneName_future: 'scene_A99_13krs_8_future',
    name: 'A99',
    lookAt: '88, 0, 140',
    face: 'east',

    relatedApartments: {
      16: 119,
      15: 112,
      14: 105,
      12: 91,
      11: 85,
      10: 78,
      9: 70,
      8: 61,
      7: 52,
      6: 43,
      5: 35,
      4: 25,
      3: 16,
      2: 7,
    },
  },
  {
    floor: 13,
    building: 'A',
    apartmentNumber: 100,
    sceneName: 'scene_A100_13krs_7_present',
    sceneName_future: 'scene_A100_13krs_7_future',
    name: 'A100',
    lookAt: '88, 0, 140',
    face: 'east',

    relatedApartments: {
      16: 119,
      15: 112,
      14: 101,
      12: 93,
      11: 86,
      10: 79,
      9: 72,
      8: 63,
      7: 54,
      6: 45,
      5: 36,
      4: 27,
      3: 18,
      2: 9,
    },
  },
]

export default FLOOR_13_DATA
