export const initialApartments = {
  2: {
    floor: 2,
    building: 'A',
    apartmentNumber: 5,
    sceneName: 'scene_A05_02krs__ita_0000',
    sceneName_future: 'scene_A05_02krs__ita_0000',
    name: 'A5',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      3: 12,
      4: 19,
      5: 26,
      6: 33,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  3: {
    floor: 3,
    building: 'A',
    apartmentNumber: 12,
    sceneName: 'scene_A12_03krs__ita_0000',
    sceneName_future: 'scene_A12_03krs__ita_0000',
    name: 'A12',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      4: 19,
      5: 26,
      6: 33,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  4: {
    floor: 4,
    building: 'A',
    apartmentNumber: 19,
    sceneName: 'scene_A19_04krs__ita_0000',
    sceneName_future: 'scene_A19_04krs__ita_0000',
    name: 'A19',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 12,
      5: 26,
      6: 33,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  5: {
    floor: 5,
    building: 'A',
    apartmentNumber: 26,
    sceneName: 'scene_A26_05krs__ita_0000',
    sceneName_future: 'scene_A26_05krs__ita_0000',
    name: 'A26',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 12,
      4: 19,
      6: 33,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  6: {
    floor: 6,
    building: 'A',
    apartmentNumber: 33,
    sceneName: 'scene_A33_06krs__ita_0000',
    sceneName_future: 'scene_A33_06krs__ita_0000',
    name: 'A33',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 12,
      4: 19,
      5: 26,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  7: {
    floor: 7,
    building: 'A',
    apartmentNumber: 34,
    sceneName: 'scene_A34_07krs__ita_0000',
    sceneName_future: 'scene_A34_07krs__ita_0000',
    name: 'A34',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  8: {
    floor: 8,
    building: 'A',
    apartmentNumber: 40,
    sceneName: 'scene_A40_08krs__ita_0000',
    sceneName_future: 'scene_A40_08krs__ita_0000',
    name: 'A40',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      7: 34,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  9: {
    floor: 9,
    building: 'A',
    apartmentNumber: 46,
    sceneName: 'scene_A46_09krs__ita_0000',
    sceneName_future: 'scene_A46_09krs__ita_0000',
    name: 'A46',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      7: 34,
      8: 40,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  10: {
    floor: 10,
    building: 'A',
    apartmentNumber: 52,
    sceneName: 'scene_A52_10krs__ita_0000',
    sceneName_future: 'scene_A52_10krs__ita_0000',
    name: 'A52',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      7: 34,
      8: 40,
      9: 46,
      11: 57,
      12: 61,
    },
  },
  11: {
    floor: 11,
    building: 'A',
    apartmentNumber: 57,
    sceneName: 'scene_A57_11krs__ita_0000',
    sceneName_future: 'scene_A57_11krs__ita_0000',
    name: 'A57',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      12: 61,
    },
  },
  12: {
    floor: 12,
    building: 'A',
    apartmentNumber: 61,
    sceneName: 'scene_A61_12krs__ita_0000',
    sceneName_future: 'scene_A61_12krs__ita_0000',
    name: 'A61',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
    },
  },
}
