const FLOOR_11_DATA = [
  {
    floor: 11,
    building: 'A',
    apartmentNumber: 57,
    sceneName: 'scene_A57_11krs__ita_0000',
    sceneName_future: 'scene_A57_11krs__ita_0000',
    name: 'A57',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      12: 61,
    },
  },
  {
    floor: 11,
    building: 'A',
    apartmentNumber: 58,
    sceneName: 'scene_A58_11krs__lansi_0000',
    sceneName_future: 'scene_A58_11krs__lansi_0000',
    name: 'A58',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 7,
      4: 14,
      5: 21,
      6: 28,
      7: 35,
      8: 41,
      9: 47,
      10: 53,
      12: 62,
    },
  },
  {
    floor: 11,
    building: 'A',
    apartmentNumber: 60,
    sceneName: 'scene_A60_11krs__etela_0000',
    sceneName_future: 'scene_A60_11krs__etela_0000',
    name: 'A60',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 3,
      3: 10,
      4: 17,
      5: 24,
      6: 31,
      7: 38,
      8: 44,
      9: 50,
      10: 56,
      12: 64,
    },
  },
]

export default FLOOR_11_DATA
