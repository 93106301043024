import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor12({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
  apartments,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="floor-12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 195 120"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="212.4033"
          y1="238.3235"
          x2="212.4033"
          y2="193.4135"
          gradientTransform="matrix(1, 0, 0, -1, -164.9032, 264.9841)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="216.2275"
          y1="157.6703"
          x2="216.2275"
          y2="112.7603"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="254.1763"
          y1="165.1098"
          x2="254.1763"
          y2="120.1998"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="309.078"
          y1="210.7031"
          x2="309.078"
          y2="165.7931"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="195" height="120" />
        </g>
      </g>
      {/* APARTMENTS */}
      <g id="apartments">
        <g
          onClick={() => changeView(12, 61)}
          onMouseOver={() => setElementOnHover('A61')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A61"
            className={`cls-2 ${
              sceneName === 'scene_A61_12krs__ita_0000'
                ? 'active-apt'
                : elementOnHover === 'A61'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A61') === 'sold' ? 'bg-gray' : ''
            }`}
            points="14.319 28.282 14.364 62.97 51.719 62.972 51.669 48.903 95.567 48.903 95.567 12.859 60.785 13.018 60.089 12.432 33.222 21.228 32.57 21.961 14.319 28.282"
          />
        </g>
        <g
          onClick={() => changeView(12, 62)}
          onMouseOver={() => setElementOnHover('A62')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A62"
            className={`cls-2 ${
              sceneName === 'scene_A62_12krs__lansi_0000'
                ? 'active-apt'
                : elementOnHover === 'A62'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A62') === 'sold' ? 'bg-gray' : ''
            }`}
            points="14.364 106.985 38.017 106.985 38.623 107.58 65.11 107.58 66.139 106.985 83.031 106.985 83.031 72.011 42.194 72.011 42.194 62.97 14.364 62.97 14.364 106.985"
          />
        </g>

        <polygon
          id="A9"
          className="cls-2"
          x="83.0308"
          y="72.1354"
          width="22.1929"
          height="34.8494"
        />
        <g
          onClick={() => changeView(12, 64)}
          onMouseOver={() => setElementOnHover('A64')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A64"
            className={`cls-2 ${
              sceneName === 'scene_A64_12krs__etela_0000'
                ? 'active-apt'
                : elementOnHover === 'A64'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A64') === 'sold' ? 'bg-gray' : ''
            }`}
            points="105.221 106.985 105.221 49.79 120.458 49.79 120.593 34.113 105.221 34.158 105.221 13.015 149.972 13.015 149.972 63.224 149.193 63.224 149.193 83.019 105.221 106.985"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polygon
          className="cls-3"
          points="14.319 106.985 38.018 106.985 38.018 96.458 65.74 96.458 65.74 106.985 105.863 106.985 149.403 82.845 149.403 63.224 138.876 63.224 138.876 35.832 149.403 35.832 149.403 13.015 60.725 13.015 60.725 24.314 36.474 32.415 33.057 21.889 14.319 28.282 14.319 106.985"
        />
        <polygon
          className="cls-4"
          points="42.095 71.977 105.221 71.977 105.221 13.015 95.567 13.015 95.567 62.972 51.286 62.972 42.095 62.972 42.095 71.977"
        />
        <polyline
          className="cls-4"
          points="95.567 48.862 51.719 48.862 51.719 62.972"
        />
        <line
          className="cls-4"
          x1="42.0951"
          y1="62.9723"
          x2="14.3186"
          y2="62.9723"
        />
        <line
          className="cls-4"
          x1="82.9947"
          y1="71.9771"
          x2="82.9947"
          y2="106.9847"
        />
        <line
          className="cls-4"
          x1="105.2213"
          y1="71.9771"
          x2="105.2213"
          y2="106.9847"
        />
        <polyline
          className="cls-5"
          points="105.221 49.79 112.469 49.79 121.051 49.79"
        />
        <polyline
          className="cls-4"
          points="105.221 33.916 120.631 33.916 120.631 49.79"
        />
        <polyline
          className="cls-6"
          points="149.972 35.832 149.972 46.873 149.972 63.224"
        />
        <line
          className="cls-6"
          x1="65.7401"
          y1="107.5804"
          x2="38.0177"
          y2="107.5804"
        />
        <line
          className="cls-6"
          x1="33.2215"
          y1="21.2281"
          x2="60.0891"
          y2="12.4317"
        />
      </g>
      {/* APARTMENTS NUMBERS */}
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`
            ${
              sceneName === 'scene_A61_12krs__ita_0000'
                ? 'text-white'
                : elementOnHover === 'A61'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A61') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(12, 61)}
          onMouseOver={() => setElementOnHover('A61')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M50.2566,39.7953H49.365l-.3545-.9219H47.3884l-.3349.9219h-.8692l1.5806-4.0586h.8667ZM48.7478,38.19l-.5591-1.5058L47.64,38.19Z"
          />
          <path
            className="cls-7"
            d="M53.4377,36.7309l-.7529.083a.5444.5444,0,0,0-.1435-.3428A.419.419,0,0,0,52.24,36.36a.5109.5109,0,0,0-.417.2217,1.8652,1.8652,0,0,0-.2143.9218.9108.9108,0,0,1,.7226-.3437,1.1038,1.1038,0,0,1,.8345.3711,1.3467,1.3467,0,0,1,.3472.958,1.3729,1.3729,0,0,1-.3653.999,1.33,1.33,0,0,1-1.9487-.1006,2.4537,2.4537,0,0,1-.396-1.5654,2.5018,2.5018,0,0,1,.4126-1.6084,1.3325,1.3325,0,0,1,1.0713-.4932,1.1413,1.1413,0,0,1,.7656.2588A1.216,1.216,0,0,1,53.4377,36.7309Zm-1.7631,1.6972a.8806.8806,0,0,0,.1743.585.512.512,0,0,0,.3989.206.4523.4523,0,0,0,.36-.1689.8506.8506,0,0,0,.1435-.5527.8877.8877,0,0,0-.1548-.58.4978.4978,0,0,0-.7666-.0088A.7627.7627,0,0,0,51.6746,38.4281Z"
          />
          <path
            className="cls-7"
            d="M56.2293,39.7953h-.7779V36.8637a2.6908,2.6908,0,0,1-1.0049.59v-.706a2.2412,2.2412,0,0,0,.6617-.378,1.4342,1.4342,0,0,0,.49-.6494h.6309Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A62_12krs__lansi_0000'
                ? 'text-white'
                : elementOnHover === 'A62'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A62') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(12, 62)}
          onMouseOver={() => setElementOnHover('A62')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M60.9387,86.47h-.8916l-.3545-.9219h-1.622l-.335.9219h-.8691l1.5805-4.0586h.8667ZM59.43,84.8647l-.559-1.5059-.5484,1.5059Z"
          />
          <path
            className="cls-7"
            d="M64.12,83.4057l-.753.083a.5444.5444,0,0,0-.1435-.3428.4187.4187,0,0,0-.3018-.1113.5109.5109,0,0,0-.417.2217,1.8663,1.8663,0,0,0-.2143.9218.9109.9109,0,0,1,.7226-.3437,1.1035,1.1035,0,0,1,.8345.3711,1.3463,1.3463,0,0,1,.3472.958,1.3729,1.3729,0,0,1-.3653.999,1.33,1.33,0,0,1-1.9487-.1006,2.4543,2.4543,0,0,1-.396-1.5654,2.5018,2.5018,0,0,1,.4126-1.6084,1.3326,1.3326,0,0,1,1.0713-.4932,1.1413,1.1413,0,0,1,.7656.2588A1.2166,1.2166,0,0,1,64.12,83.4057Zm-1.7632,1.6972a.8806.8806,0,0,0,.1743.585.5121.5121,0,0,0,.3989.2061.4524.4524,0,0,0,.36-.169.8505.8505,0,0,0,.1436-.5527.8883.8883,0,0,0-.1548-.58.4978.4978,0,0,0-.7666-.0088A.7627.7627,0,0,0,62.3567,85.1029Z"
          />
          <path
            className="cls-7"
            d="M67.5481,85.7475V86.47H64.8215a1.88,1.88,0,0,1,.2657-.7763,5.1614,5.1614,0,0,1,.875-.9727,5.5626,5.5626,0,0,0,.645-.665.8561.8561,0,0,0,.1606-.4756.5407.5407,0,0,0-.14-.4.5233.5233,0,0,0-.3862-.14.5179.5179,0,0,0-.3872.1465.753.753,0,0,0-.1661.4873l-.7754-.0771a1.2711,1.2711,0,0,1,.4346-.9219,1.46,1.46,0,0,1,.9136-.28,1.32,1.32,0,0,1,.9438.3242,1.0618,1.0618,0,0,1,.3433.8057,1.4026,1.4026,0,0,1-.0981.5215,2.1231,2.1231,0,0,1-.3116.5195,4.95,4.95,0,0,1-.5092.5176q-.3685.3369-.4664.4482a1.3566,1.3566,0,0,0-.1591.2159Z"
          />
        </g>
        <g>
          <path
            className="cls-7"
            d="M92.9075,86.4691h-.8916l-.3545-.9218H90.0393l-.335.9218h-.8691l1.5806-4.0585h.8667Zm-1.5088-1.6054L90.84,83.3578l-.5483,1.5059Z"
          />
          <path
            className="cls-7"
            d="M96.0886,83.4047l-.7529.083a.5447.5447,0,0,0-.1436-.3428.4186.4186,0,0,0-.3017-.1113.5108.5108,0,0,0-.417.2217,1.8654,1.8654,0,0,0-.2144.9219.9109.9109,0,0,1,.7227-.3438,1.1038,1.1038,0,0,1,.8345.3711,1.3466,1.3466,0,0,1,.3471.958,1.3727,1.3727,0,0,1-.3652.999,1.33,1.33,0,0,1-1.9487-.1006,2.4537,2.4537,0,0,1-.396-1.5654,2.502,2.502,0,0,1,.4126-1.6084,1.3324,1.3324,0,0,1,1.0713-.4931,1.142,1.142,0,0,1,.7656.2587A1.2167,1.2167,0,0,1,96.0886,83.4047ZM94.3254,85.102a.88.88,0,0,0,.1744.5849.5121.5121,0,0,0,.3989.2061.4522.4522,0,0,0,.36-.169.85.85,0,0,0,.1436-.5527.8883.8883,0,0,0-.1548-.58.4978.4978,0,0,0-.7666-.0088A.763.763,0,0,0,94.3254,85.102Z"
          />
          <path
            className="cls-7"
            d="M96.8626,85.392l.7529-.0908a.7105.7105,0,0,0,.1933.4394.5313.5313,0,0,0,.3824.1524.5232.5232,0,0,0,.4052-.1826.7066.7066,0,0,0,.1651-.4922.66.66,0,0,0-.1577-.4649.502.502,0,0,0-.3853-.1718,1.3816,1.3816,0,0,0-.3569.0576l.0859-.6338a.6807.6807,0,0,0,.4815-.1367.488.488,0,0,0,.1665-.3868.42.42,0,0,0-.4458-.4482.4681.4681,0,0,0-.3409.1387.6483.6483,0,0,0-.1713.4043l-.7168-.1221a1.6024,1.6024,0,0,1,.2255-.5879,1.0148,1.0148,0,0,1,.4205-.3467,1.4142,1.4142,0,0,1,.6049-.1259,1.2047,1.2047,0,0,1,.919.3662.9246.9246,0,0,1-.2988,1.5273.9472.9472,0,0,1,.5576.335.9713.9713,0,0,1,.209.6289,1.2181,1.2181,0,0,1-.39.91,1.4561,1.4561,0,0,1-1.8852.06A1.2427,1.2427,0,0,1,96.8626,85.392Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A64_12krs__etela_0000'
                ? 'text-white'
                : elementOnHover === 'A64'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A64') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(12, 64)}
          onMouseOver={() => setElementOnHover('A64')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-7"
            d="M127.5955,53.515h-.8916l-.3545-.9218h-1.6221l-.335.9218h-.8691l1.5811-4.0585h.8662ZM126.0867,51.91l-.5586-1.5059-.5488,1.5059Z"
          />
          <path
            className="cls-7"
            d="M130.7771,50.4506l-.7529.083a.5474.5474,0,0,0-.1436-.3428.42.42,0,0,0-.3017-.1113.5094.5094,0,0,0-.417.2217,1.8642,1.8642,0,0,0-.2149.9219.9116.9116,0,0,1,.7227-.3438,1.1038,1.1038,0,0,1,.8349.3711,1.3459,1.3459,0,0,1,.3467.958,1.371,1.371,0,0,1-.3652.999,1.3295,1.3295,0,0,1-1.9483-.1006,2.451,2.451,0,0,1-.3964-1.5654,2.4994,2.4994,0,0,1,.413-1.6084,1.3321,1.3321,0,0,1,1.0713-.4932,1.143,1.143,0,0,1,.7657.2588A1.2222,1.2222,0,0,1,130.7771,50.4506Zm-1.7637,1.6973a.8805.8805,0,0,0,.1748.5849.5121.5121,0,0,0,.3985.2061.454.454,0,0,0,.36-.169.85.85,0,0,0,.1436-.5527.8881.8881,0,0,0-.1553-.58.4978.4978,0,0,0-.7666-.0088A.763.763,0,0,0,129.0134,52.1479Z"
          />
          <path
            className="cls-7"
            d="M133.1033,53.515v-.8164h-1.6612V52.017l1.7608-2.5772h.6533V52.015h.5039v.6836h-.5039v.8164Zm0-1.5V50.6273l-.9326,1.3877Z"
          />
        </g>
      </g>
      {/* CONES */}
      <g id="view">
        {apartmentNumber === 61 ? (
          <g id="A61-3" data-name="A61">
            <path
              id="cone"
              className="cls-8"
              d="M50.8751,26.6806a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location"
              className={`cls-9 ${
                sceneName === 'scene_A61_12krs__ita_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A61'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 62 ? (
          <g id="A62-3" data-name="A62">
            <path
              id="cone-2"
              data-name="cone"
              className="cls-10"
              d="M54.6993,107.3338a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-2"
              data-name="location"
              className={`cls-9 ${
                sceneName === 'scene_A62_12krs__lansi_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A62'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 63 ? (
          <g id="A16-3" data-name="A16">
            <path
              id="cone-4"
              data-name="cone"
              className="cls-11"
              d="M92.6481,99.8943a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-4"
              data-name="location"
              className="cls-9"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 64 ? (
          <g id="A64-3" data-name="A64">
            <path
              id="cone-5"
              data-name="cone"
              className="cls-12"
              d="M147.55,54.301a7.4932,7.4932,0,0,1-2.89.58,7.2629,7.2629,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-5"
              data-name="location"
              className={`cls-9 ${
                sceneName === 'scene_A64_12krs__etela_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A64'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      {/* ELEVATOR */}
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-7"
          x="162.4275"
          y="33.9157"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_8" data-name="8">
          <path
            className="cls-13"
            d="M171.2927,63.7016h-1.0976V59.5648a3.79,3.79,0,0,1-1.418.8321v-.9961a3.18,3.18,0,0,0,.9336-.5332,2.0228,2.0228,0,0,0,.6914-.916h.8906Z"
          />
          <path
            className="cls-13"
            d="M176.64,62.682v1.02h-3.8477a2.6657,2.6657,0,0,1,.375-1.0957,7.2973,7.2973,0,0,1,1.2344-1.3731,7.761,7.761,0,0,0,.91-.9375,1.2046,1.2046,0,0,0,.2265-.6719.764.764,0,0,0-.1972-.5644.74.74,0,0,0-.545-.1973.7322.7322,0,0,0-.5468.2071,1.06,1.06,0,0,0-.2344.6875l-1.0938-.1094a1.7974,1.7974,0,0,1,.6133-1.3008,2.0615,2.0615,0,0,1,1.2891-.3945,1.8628,1.8628,0,0,1,1.332.457,1.4978,1.4978,0,0,1,.4844,1.1367,1.9692,1.9692,0,0,1-.1387.7363,3.0038,3.0038,0,0,1-.4394.7325,6.9057,6.9057,0,0,1-.7188.73q-.52.4776-.6582.6328a1.923,1.923,0,0,0-.2246.3047Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.5941,70.1163a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,1,0,0-14.7925Z"
          />
          <path
            className="cls-7"
            d="M177.2879,76.09l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715L167.9,76.09l4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.5941,37.2947a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,1,0,0-14.7925Z"
          />
          <path
            className="cls-7"
            d="M177.2879,46.1133l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  61: [48.1051, 22.0806],
  62: [51.9293, 102.7338],
  63: [89.8781, 95.2943],
  64: [144.7798, 49.7009],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }

  .cls-2,
  .cls-6 {
    fill: #e5e5e5;
  }

  .cls-3,
  .cls-4,
  .cls-5 {
    fill: none;
  }

  .cls-3,
  .cls-4,
  .cls-5,
  .cls-6 {
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }

  .cls-3 {
    stroke-width: 1.4173px;
  }

  .cls-4 {
    stroke-width: 0.8504px;
  }

  .cls-5 {
    stroke-width: 0.8504px;
  }

  .cls-6 {
    stroke-width: 0.2835px;
  }

  .cls-7 {
    fill: #3f3f3c;
  }

  .cls-8 {
    fill: url(#linear-gradient);
  }

  .cls-9 {
    fill: #c65805;
  }

  .cls-10 {
    fill: url(#linear-gradient-2);
  }

  .cls-11 {
    fill: url(#linear-gradient-3);
  }

  .cls-12 {
    fill: url(#linear-gradient-4);
  }

  .cls-13 {
    fill: #e7e7e7;
  }

  #switch-up:hover,
  #switch-down:hover {
    cursor: pointer;

    .st0 {
      transition: fill 0.3s ease;
      fill: var(--secondary-color);
    }
  }

  #up:hover,
  #down:hover {
    cursor: pointer;

    .cls-1 {
      transition: fill 0.3s ease;
      fill: var(--secondary-color);
    }

    .cls-7 {
      transition: fill 0.3s ease;
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--primary-color);
  }

  .active {
    .st8 {
      fill: var(--primary-color);
    }
  }

  .on-hover {
    transition: fill 0.3s ease;
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text {
    fill: #ffffff;
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white .cls-7 {
    fill: #fff;
    cursor: pointer;
  }

  .text-white-sold .cls-7 {
    fill: #fff;
    cursor: pointer;
  }

  .circle-white {
    fill: #fff;
  }

  .reserved {
    fill: yellow;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
