import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor5({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
  apartments,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="floor-5"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 195 120"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="205.3938"
          y1="235.8948"
          x2="205.3938"
          y2="190.9848"
          gradientTransform="matrix(1, 0, 0, -1, -164.9032, 264.9841)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="210.8696"
          y1="157.6703"
          x2="210.8696"
          y2="112.7603"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="223.9038"
          y1="157.6703"
          x2="223.9038"
          y2="112.7603"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="254.1763"
          y1="165.1098"
          x2="254.1763"
          y2="120.1998"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="309.078"
          y1="206.2305"
          x2="309.078"
          y2="161.3205"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="309.078"
          y1="218.8639"
          x2="309.078"
          y2="173.9539"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="217.6874"
          y1="240.0823"
          x2="217.6874"
          y2="195.1723"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="195" height="120" />
        </g>
      </g>
      {/* APARTMENTS */}
      <g id="apartments">
        <g
          onClick={() => changeView(5, 20)}
          onMouseOver={() => setElementOnHover('A20')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A20"
            className={`cls-2 ${
              sceneName === 'scene_A20_05krs__ita_0000'
                ? 'active-apt'
                : elementOnHover === 'A20'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A20') === 'sold' ? 'bg-gray' : ''
            }`}
            points="32.886 22.01 14.364 28.237 14.364 62.972 51.669 62.972 51.669 48.903 45.175 29.509 47.997 28.566 44.35 17.585 33.222 21.228 32.886 22.01"
          />
        </g>
        <g
          onClick={() => changeView(5, 21)}
          onMouseOver={() => setElementOnHover('A21')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A21"
            className={`cls-2 ${
              sceneName === 'scene_A21_05krs__lansi_0000'
                ? 'active-apt'
                : elementOnHover === 'A21'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A21') === 'sold' ? 'bg-gray' : ''
            }`}
            points="14.364 106.985 38.017 106.985 38.64 107.611 53.969 107.611 53.969 96.575 55.349 96.575 55.349 72.011 42.194 72.011 42.194 62.97 14.364 62.97 14.364 106.985"
          />
        </g>
        <g
          onClick={() => changeView(5, 22)}
          onMouseOver={() => setElementOnHover('A22')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A22"
            className={`cls-2 ${
              sceneName === 'scene_A20_05krs__lansi_0000'
                ? 'active-apt'
                : elementOnHover === 'A22'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A22') === 'sold' ? 'bg-gray' : ''
            }`}
            points="83.031 72.135 55.349 72.135 55.349 96.575 53.969 96.575 53.969 107.611 83.031 107.611 83.031 72.135"
          />
        </g>
        <polygon
          id="A9"
          className="cls-2"
          x="83.0308"
          y="72.1354"
          width="22.1929"
          height="34.8494"
        />
        <g
          onClick={() => changeView(5, 24)}
          onMouseOver={() => setElementOnHover('A24')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A24"
            className={`cls-2 ${
              sceneName === 'scene_A24_05krs__etela_0000'
                ? 'active-apt'
                : elementOnHover === 'A24'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A24') === 'sold' ? 'bg-gray' : ''
            }`}
            points="105.221 106.985 105.221 49.79 138.822 49.79 138.822 46.727 149.972 46.727 149.972 63.224 149.193 63.224 149.193 83.019 105.221 106.985"
          />
        </g>
        <g
          onClick={() => changeView(5, 25)}
          onMouseOver={() => setElementOnHover('A25')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A25"
            className={`cls-2 ${
              sceneName === 'scene_A25_05krs__etela_0000'
                ? 'active-apt'
                : elementOnHover === 'A25'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A25') === 'sold' ? 'bg-gray' : ''
            }`}
            points="120.631 49.79 120.631 33.916 105.221 33.916 105.221 13.015 149.403 13.015 149.403 35.832 149.972 35.832 149.972 46.727 138.822 46.727 138.822 49.79 120.631 49.79"
          />
        </g>
        <g
          onClick={() => changeView(5, 26)}
          onMouseOver={() => setElementOnHover('A26')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A26"
            className={`cls-2 ${
              sceneName === 'scene_A26_05krs__ita_0000'
                ? 'active-apt'
                : elementOnHover === 'A26'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A26') === 'sold' ? 'bg-gray' : ''
            }`}
            points="44.35 17.585 47.997 28.566 45.175 29.509 51.669 48.903 95.567 48.903 95.567 12.859 60.785 13.018 60.089 12.432 44.35 17.585"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polygon
          className="cls-3"
          points="14.319 106.985 38.018 106.985 38.018 96.458 65.74 96.458 65.74 106.985 105.863 106.985 149.403 82.845 149.403 63.224 138.876 63.224 138.876 35.832 149.403 35.832 149.403 13.015 60.725 13.015 60.725 24.314 36.474 32.415 33.057 21.889 14.319 28.282 14.319 106.985"
        />
        <polygon
          className="cls-4"
          points="42.095 71.977 105.221 71.977 105.221 13.015 95.567 13.015 95.567 62.972 51.286 62.972 42.095 62.972 42.095 71.977"
        />
        <polyline
          className="cls-4"
          points="95.567 48.862 51.719 48.862 51.719 62.972"
        />
        <line
          className="cls-4"
          x1="45.1749"
          y1="29.5087"
          x2="51.7192"
          y2="48.8618"
        />
        <line
          className="cls-4"
          x1="42.0951"
          y1="62.9723"
          x2="14.3186"
          y2="62.9723"
        />
        <line
          className="cls-4"
          x1="55.3702"
          y1="71.9771"
          x2="55.3702"
          y2="96.458"
        />
        <line
          className="cls-4"
          x1="82.9947"
          y1="71.9771"
          x2="82.9947"
          y2="106.9847"
        />
        <line
          className="cls-4"
          x1="105.2213"
          y1="71.9771"
          x2="105.2213"
          y2="106.9847"
        />
        <polyline
          className="cls-4"
          points="105.221 49.79 120.631 49.79 138.876 49.79"
        />
        <polyline
          className="cls-4"
          points="105.221 33.916 120.631 33.916 120.631 49.79"
        />
        <polyline
          className="cls-5"
          points="149.972 35.832 149.972 46.873 149.972 63.224"
        />
        <line
          className="cls-5"
          x1="138.8764"
          y1="46.7274"
          x2="149.9715"
          y2="46.7274"
        />
        <line
          className="cls-5"
          x1="65.7401"
          y1="107.5804"
          x2="38.0177"
          y2="107.5804"
        />
        <line
          className="cls-5"
          x1="53.9688"
          y1="96.458"
          x2="53.9688"
          y2="107.5804"
        />
        <line
          className="cls-5"
          x1="33.2215"
          y1="21.2281"
          x2="60.0891"
          y2="12.4317"
        />
        <line
          className="cls-5"
          x1="47.9965"
          y1="28.566"
          x2="44.3503"
          y2="17.5845"
        />
      </g>
      {/* APARTMENTS NUMBERS */}
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`
            ${
              sceneName === 'scene_A20_05krs__ita_0000'
                ? 'text-white'
                : elementOnHover === 'A20'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A20') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(5, 20)}
          onMouseOver={() => setElementOnHover('A20')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M32.1965,45.5h-.8916l-.3545-.9219h-1.622l-.335.9219h-.8691l1.58-4.0586h.8667Zm-1.5088-1.6055-.559-1.5058L29.58,43.8949Z"
          />
          <path
            className="cls-6"
            d="M35.3694,44.7777V45.5H32.6428a1.88,1.88,0,0,1,.2657-.7764,5.1647,5.1647,0,0,1,.875-.9726,5.5685,5.5685,0,0,0,.645-.6651.8556.8556,0,0,0,.1606-.4756.5408.5408,0,0,0-.14-.4.5234.5234,0,0,0-.3863-.14.5177.5177,0,0,0-.3872.1465.7526.7526,0,0,0-.166.4873l-.7754-.0772a1.2708,1.2708,0,0,1,.4346-.9218,1.46,1.46,0,0,1,.9136-.28,1.32,1.32,0,0,1,.9438.3242,1.0617,1.0617,0,0,1,.3433.8057,1.401,1.401,0,0,1-.0982.5215,2.1212,2.1212,0,0,1-.3115.52,4.9536,4.9536,0,0,1-.5093.5176q-.3684.3369-.4663.4482a1.3516,1.3516,0,0,0-.1591.2158Z"
          />
          <path
            className="cls-6"
            d="M37.4939,41.4252a1.11,1.11,0,0,1,.9219.4209,2.69,2.69,0,0,1,.396,1.6533,2.6892,2.6892,0,0,1-.399,1.6553,1.1072,1.1072,0,0,1-.9189.415,1.1634,1.1634,0,0,1-.9551-.455,2.6938,2.6938,0,0,1-.3623-1.6241,2.6811,2.6811,0,0,1,.3985-1.6494A1.1061,1.1061,0,0,1,37.4939,41.4252Zm0,.6455a.3906.3906,0,0,0-.252.09.6.6,0,0,0-.1713.3222,4.4319,4.4319,0,0,0-.0806,1.0166,4.3757,4.3757,0,0,0,.0723.98.6921.6921,0,0,0,.1811.3564.4.4,0,0,0,.5025-.0019.6042.6042,0,0,0,.1718-.3223,6.5866,6.5866,0,0,0,.0083-1.9941.7.7,0,0,0-.1816-.3574A.3847.3847,0,0,0,37.4939,42.0707Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A21_05krs__lansi_0000'
                ? 'text-white'
                : elementOnHover === 'A21'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A21') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(5, 21)}
          onMouseOver={() => setElementOnHover('A21')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M32.1975,86.4691h-.8916l-.3545-.9218H29.3293l-.3349.9218h-.8692l1.5806-4.0585h.8667Zm-1.5088-1.6054L30.13,83.3578l-.5483,1.5059Z"
          />
          <path
            className="cls-6"
            d="M35.37,85.7465v.7226H32.6438a1.8808,1.8808,0,0,1,.2656-.7763,5.1687,5.1687,0,0,1,.875-.9727,5.5456,5.5456,0,0,0,.645-.665A.8555.8555,0,0,0,34.59,83.58a.5412.5412,0,0,0-.14-.4.5233.5233,0,0,0-.3863-.14.518.518,0,0,0-.3872.1464.753.753,0,0,0-.166.4873l-.7754-.0771a1.2707,1.2707,0,0,1,.4346-.9219,1.46,1.46,0,0,1,.9136-.28,1.32,1.32,0,0,1,.9438.3242,1.0615,1.0615,0,0,1,.3433.8056,1.4,1.4,0,0,1-.0982.5215,2.1175,2.1175,0,0,1-.3115.52,4.9541,4.9541,0,0,1-.5093.5176q-.3684.3369-.4663.4483a1.3587,1.3587,0,0,0-.1592.2158Z"
          />
          <path
            className="cls-6"
            d="M38.17,86.4691h-.7779V83.5375a2.69,2.69,0,0,1-1.0048.59v-.706a2.2438,2.2438,0,0,0,.6616-.3779,1.4346,1.4346,0,0,0,.49-.6494H38.17Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A20_05krs__lansi_0000'
                ? 'text-white'
                : elementOnHover === 'A22'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A22') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(5, 22)}
          onMouseOver={() => setElementOnHover('A22')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M69.4485,86.47h-.8916l-.3545-.9219H66.58l-.3349.9219h-.8692l1.5806-4.0586h.8667ZM67.94,84.8647l-.5591-1.5059-.5483,1.5059Z"
          />
          <path
            className="cls-6"
            d="M72.6213,85.7475V86.47H69.8948a1.8793,1.8793,0,0,1,.2656-.7763,5.1687,5.1687,0,0,1,.875-.9727,5.5541,5.5541,0,0,0,.645-.665.8555.8555,0,0,0,.1607-.4756.5407.5407,0,0,0-.14-.4.5941.5941,0,0,0-.7734.0068.7525.7525,0,0,0-.166.4873l-.7754-.0771a1.2707,1.2707,0,0,1,.4346-.9219,1.4594,1.4594,0,0,1,.9135-.28,1.32,1.32,0,0,1,.9439.3242,1.0617,1.0617,0,0,1,.3432.8057,1.4026,1.4026,0,0,1-.0981.5215,2.1194,2.1194,0,0,1-.3115.5195,4.9641,4.9641,0,0,1-.5093.5176q-.3684.3369-.4663.4482a1.3576,1.3576,0,0,0-.1592.2159Z"
          />
          <path
            className="cls-6"
            d="M76.0579,85.7475V86.47H73.3313a1.8808,1.8808,0,0,1,.2656-.7763,5.1687,5.1687,0,0,1,.875-.9727,5.5456,5.5456,0,0,0,.645-.665.8555.8555,0,0,0,.1607-.4756.5411.5411,0,0,0-.14-.4.5234.5234,0,0,0-.3863-.14.5181.5181,0,0,0-.3872.1465.753.753,0,0,0-.166.4873l-.7754-.0771a1.2707,1.2707,0,0,1,.4346-.9219,1.46,1.46,0,0,1,.9136-.28,1.32,1.32,0,0,1,.9438.3242,1.0618,1.0618,0,0,1,.3433.8057,1.4007,1.4007,0,0,1-.0982.5215,2.1194,2.1194,0,0,1-.3115.5195,4.9641,4.9641,0,0,1-.5093.5176q-.3684.3369-.4663.4482a1.3638,1.3638,0,0,0-.1592.2159Z"
          />
        </g>
        <g>
          <path
            className="cls-6"
            d="M92.9075,86.4691h-.8916l-.3545-.9218H90.0393l-.335.9218h-.8691l1.5806-4.0585h.8667Zm-1.5088-1.6054L90.84,83.3578l-.5483,1.5059Z"
          />
          <path
            className="cls-6"
            d="M96.08,85.7465v.7226H93.3538a1.8779,1.8779,0,0,1,.2656-.7763,5.1614,5.1614,0,0,1,.875-.9727,5.5541,5.5541,0,0,0,.645-.665A.8563.8563,0,0,0,95.3,83.58a.5409.5409,0,0,0-.14-.4.5944.5944,0,0,0-.7734.0068.7525.7525,0,0,0-.166.4873l-.7754-.0771a1.271,1.271,0,0,1,.4345-.9219,1.46,1.46,0,0,1,.9136-.28,1.32,1.32,0,0,1,.9439.3242,1.0614,1.0614,0,0,1,.3432.8056,1.4022,1.4022,0,0,1-.0981.5215,2.1207,2.1207,0,0,1-.3115.52,4.9541,4.9541,0,0,1-.5093.5176q-.3684.3369-.4663.4483a1.3465,1.3465,0,0,0-.1592.2158Z"
          />
          <path
            className="cls-6"
            d="M96.8626,85.392l.7529-.0908a.7105.7105,0,0,0,.1933.4394.5313.5313,0,0,0,.3824.1524.5232.5232,0,0,0,.4052-.1826.7066.7066,0,0,0,.1651-.4922.66.66,0,0,0-.1577-.4649.502.502,0,0,0-.3853-.1718,1.3816,1.3816,0,0,0-.3569.0576l.0859-.6338a.6807.6807,0,0,0,.4815-.1367.488.488,0,0,0,.1665-.3868.42.42,0,0,0-.4458-.4482.4681.4681,0,0,0-.3409.1387.6483.6483,0,0,0-.1713.4043l-.7168-.1221a1.6024,1.6024,0,0,1,.2255-.5879,1.0148,1.0148,0,0,1,.4205-.3467,1.4142,1.4142,0,0,1,.6049-.1259,1.2047,1.2047,0,0,1,.919.3662.9246.9246,0,0,1-.2988,1.5273.9472.9472,0,0,1,.5576.335.9713.9713,0,0,1,.209.6289,1.2181,1.2181,0,0,1-.39.91,1.4561,1.4561,0,0,1-1.8852.06A1.2427,1.2427,0,0,1,96.8626,85.392Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A24_05krs__etela_0000'
                ? 'text-white'
                : elementOnHover === 'A24'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A24') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(5, 24)}
          onMouseOver={() => setElementOnHover('A24')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M127.5955,74.5277h-.8916l-.3545-.9218h-1.6221l-.335.9218h-.8691l1.5811-4.0586h.8662Zm-1.5088-1.6054-.5586-1.5059-.5488,1.5059Z"
          />
          <path
            className="cls-6"
            d="M130.7683,73.8051v.7226h-2.7265a1.8851,1.8851,0,0,1,.2656-.7763,5.18,5.18,0,0,1,.875-.9727,5.617,5.617,0,0,0,.6455-.665.8592.8592,0,0,0,.16-.4756.5426.5426,0,0,0-.14-.4.5244.5244,0,0,0-.3867-.14.5169.5169,0,0,0-.3867.1464.75.75,0,0,0-.1661.4873l-.7754-.0771a1.2674,1.2674,0,0,1,.4346-.9219,1.4574,1.4574,0,0,1,.9131-.28,1.3209,1.3209,0,0,1,.9443.3243,1.0624,1.0624,0,0,1,.3428.8056,1.4022,1.4022,0,0,1-.0976.5215,2.1371,2.1371,0,0,1-.3116.52,4.9063,4.9063,0,0,1-.51.5176q-.3677.3369-.4659.4483a1.3216,1.3216,0,0,0-.1591.2158Z"
          />
          <path
            className="cls-6"
            d="M133.1033,74.5277v-.8164h-1.6612V73.03l1.7608-2.5772h.6533v2.5752h.5039v.6836h-.5039v.8164Zm0-1.5V71.64l-.9326,1.3877Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A25_05krs__etela_0000'
                ? 'text-white'
                : elementOnHover === 'A25'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A25') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(5, 25)}
          onMouseOver={() => setElementOnHover('A25')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M127.5964,30.5126h-.8916l-.3545-.9219h-1.622l-.335.9219h-.8691l1.58-4.0586h.8671Zm-1.5087-1.6055-.56-1.5058-.5479,1.5058Z"
          />
          <path
            className="cls-6"
            d="M130.7693,29.79v.7227h-2.7266a1.8733,1.8733,0,0,1,.2657-.7764,5.1467,5.1467,0,0,1,.875-.9726,5.4988,5.4988,0,0,0,.6445-.6651.8525.8525,0,0,0,.1611-.4755.5391.5391,0,0,0-.1406-.4.5941.5941,0,0,0-.7734.0068.7556.7556,0,0,0-.1661.4873l-.7754-.0771a1.2746,1.2746,0,0,1,.4346-.9219,1.4622,1.4622,0,0,1,.9141-.28,1.3188,1.3188,0,0,1,.9433.3242,1.06,1.06,0,0,1,.3438.8057,1.4034,1.4034,0,0,1-.0986.5215,2.1094,2.1094,0,0,1-.3116.52,4.9776,4.9776,0,0,1-.5088.5176q-.369.3369-.4668.4482a1.3829,1.3829,0,0,0-.1591.2158Z"
          />
          <path
            className="cls-6"
            d="M131.59,29.4687l.7754-.08a.6692.6692,0,0,0,.1963.417.5338.5338,0,0,0,.3769.1533.52.52,0,0,0,.4121-.1982.8993.8993,0,0,0,.169-.5958.8114.8114,0,0,0-.168-.5605.5561.5561,0,0,0-.4355-.1875.7909.7909,0,0,0-.6006.2959l-.6309-.0908.3985-2.1123h2.0566v.7285h-1.4668l-.1221.6885a1.1834,1.1834,0,0,1,.5313-.13,1.17,1.17,0,0,1,.8779.3769,1.3568,1.3568,0,0,1,.3594.9766,1.4682,1.4682,0,0,1-.29.8945,1.29,1.29,0,0,1-1.1.5371,1.3645,1.3645,0,0,1-.916-.3017A1.2422,1.2422,0,0,1,131.59,29.4687Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A26_05krs__ita_0000'
                ? 'text-white'
                : elementOnHover === 'A26'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A26') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(5, 26)}
          onMouseOver={() => setElementOnHover('A26')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M76.0457,30.5126h-.8916L74.8,29.5907H73.1775l-.335.9219h-.8691L73.554,26.454h.8667Zm-1.5088-1.6055-.5591-1.5058-.5484,1.5058Z"
          />
          <path
            className="cls-6"
            d="M79.2185,29.79v.7227H76.4919a1.88,1.88,0,0,1,.2657-.7764,5.1647,5.1647,0,0,1,.875-.9726,5.5521,5.5521,0,0,0,.645-.6651.8556.8556,0,0,0,.1606-.4755.5405.5405,0,0,0-.14-.4.5233.5233,0,0,0-.3862-.14.5177.5177,0,0,0-.3872.1465.7528.7528,0,0,0-.1661.4873l-.7753-.0771a1.27,1.27,0,0,1,.4345-.9219,1.46,1.46,0,0,1,.9136-.28,1.32,1.32,0,0,1,.9438.3242,1.0613,1.0613,0,0,1,.3433.8057,1.403,1.403,0,0,1-.0981.5215,2.125,2.125,0,0,1-.3116.52,4.95,4.95,0,0,1-.5092.5176q-.3684.3369-.4663.4482a1.3465,1.3465,0,0,0-.1592.2158Z"
          />
          <path
            className="cls-6"
            d="M82.6633,27.4482l-.7529.083a.5444.5444,0,0,0-.1436-.3428.4186.4186,0,0,0-.3017-.1113.5109.5109,0,0,0-.417.2216,1.8657,1.8657,0,0,0-.2144.9219.9111.9111,0,0,1,.7227-.3437,1.1038,1.1038,0,0,1,.8345.3711,1.3466,1.3466,0,0,1,.3471.958,1.3728,1.3728,0,0,1-.3652.999,1.33,1.33,0,0,1-1.9487-.1006,2.4538,2.4538,0,0,1-.396-1.5654,2.5018,2.5018,0,0,1,.4126-1.6084,1.3322,1.3322,0,0,1,1.0713-.4932,1.1415,1.1415,0,0,1,.7656.2588A1.2157,1.2157,0,0,1,82.6633,27.4482ZM80.9,29.1454a.8808.8808,0,0,0,.1743.585.512.512,0,0,0,.3989.206.4525.4525,0,0,0,.36-.1689.8506.8506,0,0,0,.1435-.5527.8881.8881,0,0,0-.1548-.58.4978.4978,0,0,0-.7666-.0088A.7627.7627,0,0,0,80.9,29.1454Z"
          />
        </g>
      </g>
      {/* CONES */}
      <g id="view">
        {apartmentNumber === 20 ? (
          <g id="A20-3" data-name="A20">
            <path
              id="cone"
              className="cls-7"
              d="M43.8656,29.1093a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location"
              className={`cls-8 ${
                sceneName === 'scene_A20_05krs__ita_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A20'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 21 ? (
          <g id="A21-3" data-name="A21">
            <path
              id="cone-2"
              data-name="cone"
              className="cls-9"
              d="M49.3414,107.3338a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-2"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A21_05krs__lansi_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A21'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 22 ? (
          <g id="A22-3" data-name="A22">
            <path
              id="cone-3"
              data-name="cone"
              className="cls-10"
              d="M62.3756,107.3338a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-3"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A20_05krs__lansi_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A22'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 23 ? (
          <g id="A16-3" data-name="A16">
            <path
              id="cone-4"
              data-name="cone"
              className="cls-11"
              d="M92.6481,99.8943a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-4"
              data-name="location"
              className="cls-8"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 24 ? (
          <g id="A24-3" data-name="A24">
            <path
              id="cone-5"
              data-name="cone"
              className="cls-12"
              d="M147.55,58.7736a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-5"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A24_05krs__etela_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A24'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 25 ? (
          <g id="A25-3" data-name="A25">
            <path
              id="cone-6"
              data-name="cone"
              className="cls-13"
              d="M147.55,46.14a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6L122.28,91.03h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-6"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A25_05krs__etela_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A25'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 26 ? (
          <g id="A26-3" data-name="A26">
            <path
              id="cone-7"
              data-name="cone"
              className="cls-14"
              d="M56.1592,24.9218a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-7"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A26_05krs__ita_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A26'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      {/* ELEVATOR */}
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-6"
          x="162.4275"
          y="33.9157"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_8" data-name="8">
          <path
            className="cls-15"
            d="M170.7253,62.2289l1.0938-.1133a.9379.9379,0,0,0,.2773.5879.7525.7525,0,0,0,.5313.2168.7366.7366,0,0,0,.582-.2793,1.2676,1.2676,0,0,0,.2383-.8418,1.1551,1.1551,0,0,0-.2363-.791.7866.7866,0,0,0-.6153-.2637,1.1186,1.1186,0,0,0-.8476.418l-.8906-.1289.5625-2.98h2.9023v1.0274h-2.07l-.1719.9726a1.6779,1.6779,0,0,1,1.9883.3477,1.9131,1.9131,0,0,1,.5078,1.3789,2.0657,2.0657,0,0,1-.41,1.2617,1.8166,1.8166,0,0,1-1.5508.7578,1.9232,1.9232,0,0,1-1.293-.4258A1.7545,1.7545,0,0,1,170.7253,62.2289Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.5941,70.1163a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,1,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M177.2879,76.09l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715L167.9,76.09l4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.5941,37.2947a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,1,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M177.2879,46.1133l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  20: [41.0956, 24.5093],
  21: [46.5714, 102.7338],
  22: [59.6056, 102.7338],
  23: [89.8781, 95.2943],
  24: [144.7798, 54.1736],
  25: [144.7798, 41.5402],
  26: [53.3892, 20.3218],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }

  .cls-2,
  .cls-5 {
    fill: #e5e5e5;
  }

  .cls-3,
  .cls-4 {
    fill: none;
  }

  .cls-3,
  .cls-4,
  .cls-5 {
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }

  .cls-3 {
    stroke-width: 1.4173px;
  }

  .cls-4 {
    stroke-width: 0.8504px;
  }

  .cls-5 {
    stroke-width: 0.2835px;
  }

  .cls-6 {
    fill: #3f3f3c;
  }

  .cls-7 {
    fill: url(#linear-gradient);
  }

  .cls-8 {
    fill: #c65805;
  }

  .cls-9 {
    fill: url(#linear-gradient-2);
  }

  .cls-10 {
    fill: url(#linear-gradient-3);
  }

  .cls-11 {
    fill: url(#linear-gradient-4);
  }

  .cls-12 {
    fill: url(#linear-gradient-5);
  }

  .cls-13 {
    fill: url(#linear-gradient-6);
  }

  .cls-14 {
    fill: url(#linear-gradient-7);
  }

  .cls-15 {
    fill: #e7e7e7;
  }

  #switch-up:hover,
  #switch-down:hover {
    cursor: pointer;

    .st0 {
      transition: fill 0.3s ease;
      fill: var(--secondary-color);
    }
  }

  #up:hover,
  #down:hover {
    cursor: pointer;

    .cls-1 {
      transition: fill 0.3s ease;
      fill: var(--secondary-color);
    }

    .cls-6 {
      transition: fill 0.3s ease;
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--primary-color);
  }

  .active {
    .st8 {
      fill: var(--primary-color);
    }
  }

  .on-hover {
    transition: fill 0.3s ease;
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text {
    fill: #ffffff;
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white .cls-6 {
    fill: #fff;
    cursor: pointer;
  }

  .text-white-sold .cls-6 {
    fill: #fff;
    cursor: pointer;
  }

  .circle-white {
    fill: #fff;
  }

  .reserved {
    fill: yellow;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
