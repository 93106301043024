const FLOOR_3_DATA = [
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 6,
    sceneName: 'scene_A06_03krs__ita_0000',
    sceneName_future: 'scene_A06_03krs__ita_0000',
    name: 'A6',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      4: 13,
      5: 20,
      6: 27,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 7,
    sceneName: 'scene_A07_03krs__lansi_0000',
    sceneName_future: 'scene_A07_03krs__lansi_0000',
    name: 'A7',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      4: 14,
      5: 21,
      6: 28,
      7: 35,
      8: 41,
      9: 47,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 8,
    sceneName: 'scene_A08_03krs__lansi_0000',
    sceneName_future: 'scene_A08_03krs__lansi_0000',
    name: 'A8',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      4: 15,
      5: 22,
      6: 29,
      7: 36,
      8: 42,
      9: 48,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 10,
    sceneName: 'scene_A10_03krs__etela_0000',
    sceneName_future: 'scene_A10_03krs__etela_0000',
    name: 'A10',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 3,
      4: 17,
      5: 24,
      6: 31,
      7: 38,
      8: 44,
      9: 50,
      10: 55,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 11,
    sceneName: 'scene_A11_03krs__etela_0000',
    sceneName_future: 'scene_A11_03krs__etela_0000',
    name: 'A11',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 4,
      4: 18,
      5: 25,
      6: 32,
      7: 39,
      8: 45,
      9: 51,
      10: 56,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 3,
    building: 'A',
    apartmentNumber: 12,
    sceneName: 'scene_A12_03krs__ita_0000',
    sceneName_future: 'scene_A12_03krs__ita_0000',
    name: 'A12',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      4: 19,
      5: 26,
      6: 33,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
]

export default FLOOR_3_DATA
