import { projectFirestore } from 'firebase/config'
import React from 'react'

export default function useCollection(collection) {
  const [documents, setDocuments] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    let ref = projectFirestore.collection(collection)

    const unsubscribe = ref.onSnapshot(
      snapshot => {
        setIsLoading(true)
        if (snapshot.empty) {
          setError('No data')
          setIsLoading(false)
        } else {
          let results = {}
          snapshot.docs.forEach(doc => (results[doc.id] = doc.data()))
          setDocuments(results)
          setIsLoading(false)
        }
      },
      error => {
        setError(error)
        setIsLoading(false)
      },
    )

    return () => unsubscribe()
  }, [collection])

  return { documents, error, isLoading }
}
