import * as React from 'react'
import styled from 'styled-components'
import { getApartmentStatus } from 'utils/helper'

export default function Floor7({
  activeApartment,
  changeFloor,
  changeView,
  hLookAt,
  toggleSceneNameFuture,
  isSceneNameFuture,
  apartments,
}) {
  const [elementOnHover, setElementOnHover] = React.useState('')
  const { sceneName, apartmentNumber } = activeApartment

  return (
    <SVG
      id="floor-7"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 195 120"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="212.4033"
          y1="238.3235"
          x2="212.4033"
          y2="193.4135"
          gradientTransform="matrix(1, 0, 0, -1, -164.9032, 264.9841)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0102" stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="210.8696"
          y1="157.6703"
          x2="210.8696"
          y2="112.7603"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-3"
          x1="223.9038"
          y1="157.6703"
          x2="223.9038"
          y2="112.7603"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="254.1763"
          y1="165.1098"
          x2="254.1763"
          y2="120.1998"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="309.078"
          y1="206.2305"
          x2="309.078"
          y2="161.3205"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="309.078"
          y1="218.8639"
          x2="309.078"
          y2="173.9539"
          xlinkHref="#linear-gradient"
        />
      </defs>
      <g id="bg">
        <g id="static">
          <rect id="plate" className="cls-1" width="195" height="120" />
        </g>
      </g>
      {/* APARTMENTS */}
      <g id="apartments">
        <g
          onClick={() => changeView(7, 34)}
          onMouseOver={() => setElementOnHover('A34')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A34"
            className={`cls-2 ${
              sceneName === 'scene_A34_07krs__ita_0000'
                ? 'active-apt'
                : elementOnHover === 'A34'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A34') === 'sold' ? 'bg-gray' : ''
            }`}
            points="14.319 28.282 14.364 62.97 51.719 62.972 51.669 48.903 95.567 48.903 95.567 12.859 60.785 13.018 60.089 12.432 33.222 21.228 32.57 21.961 14.319 28.282"
          />
        </g>
        <g
          onClick={() => changeView(7, 35)}
          onMouseOver={() => setElementOnHover('A35')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A35"
            className={`cls-2 ${
              sceneName === 'scene_A35_07krs__lansi_0000'
                ? 'active-apt'
                : elementOnHover === 'A35'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A35') === 'sold' ? 'bg-gray' : ''
            }`}
            points="14.364 106.985 38.017 106.985 38.64 107.611 53.969 107.611 53.969 96.575 55.349 96.575 55.349 72.011 42.194 72.011 42.194 62.97 14.364 62.97 14.364 106.985"
          />
        </g>
        <g
          onClick={() => changeView(7, 36)}
          onMouseOver={() => setElementOnHover('A36')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polyline
            id="A36"
            className={`cls-2 ${
              sceneName === 'scene_A36_07krs__lansi_0000'
                ? 'active-apt'
                : elementOnHover === 'A36'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A36') === 'sold' ? 'bg-gray' : ''
            }`}
            points="83.031 72.135 55.349 72.135 55.349 96.575 53.969 96.575 53.969 107.611 83.031 107.611 83.031 72.135"
          />
        </g>
        <polygon
          id="A9"
          className="cls-2"
          x="83.0308"
          y="72.1354"
          width="22.1929"
          height="34.8494"
        />
        <g
          onClick={() => changeView(7, 38)}
          onMouseOver={() => setElementOnHover('A38')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A38"
            className={`cls-2 ${
              sceneName === 'scene_A38_07krs__etela_0000'
                ? 'active-apt'
                : elementOnHover === 'A38'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A38') === 'sold' ? 'bg-gray' : ''
            }`}
            points="105.221 106.985 105.221 49.79 138.822 49.79 138.822 46.727 149.972 46.727 149.972 63.224 149.193 63.224 149.193 83.019 105.221 106.985"
          />
        </g>
        <g
          onClick={() => changeView(7, 39)}
          onMouseOver={() => setElementOnHover('A39')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <polygon
            id="A39"
            className={`cls-2 ${
              sceneName === 'scene_A39_07krs__etela_0000'
                ? 'active-apt'
                : elementOnHover === 'A39'
                ? 'on-hover'
                : ''
            } ${
              getApartmentStatus(apartments, 'A39') === 'sold' ? 'bg-gray' : ''
            }`}
            points="120.631 49.79 120.631 33.916 105.221 33.916 105.221 13.015 149.403 13.015 149.403 35.832 149.972 35.832 149.972 46.727 138.822 46.727 138.822 49.79 120.631 49.79"
          />
        </g>
      </g>
      <g id="levelfloor">
        <polygon
          className="cls-3"
          points="14.319 106.985 38.018 106.985 38.018 96.458 65.74 96.458 65.74 106.985 105.863 106.985 149.403 82.845 149.403 63.224 138.876 63.224 138.876 35.832 149.403 35.832 149.403 13.015 60.725 13.015 60.725 24.314 36.474 32.415 33.057 21.889 14.319 28.282 14.319 106.985"
        />
        <polygon
          className="cls-4"
          points="42.095 71.977 105.221 71.977 105.221 13.015 95.567 13.015 95.567 62.972 51.286 62.972 42.095 62.972 42.095 71.977"
        />
        <polyline
          className="cls-4"
          points="95.567 48.862 51.719 48.862 51.719 62.972"
        />
        <line
          className="cls-4"
          x1="42.0951"
          y1="62.9723"
          x2="14.3186"
          y2="62.9723"
        />
        <line
          className="cls-4"
          x1="55.3702"
          y1="71.9771"
          x2="55.3702"
          y2="96.458"
        />
        <line
          className="cls-4"
          x1="82.9947"
          y1="71.9771"
          x2="82.9947"
          y2="106.9847"
        />
        <line
          className="cls-4"
          x1="105.2213"
          y1="71.9771"
          x2="105.2213"
          y2="106.9847"
        />
        <polyline
          className="cls-4"
          points="105.221 49.79 120.631 49.79 138.876 49.79"
        />
        <polyline
          className="cls-4"
          points="105.221 33.916 120.631 33.916 120.631 49.79"
        />
        <polyline
          className="cls-5"
          points="149.972 35.832 149.972 46.873 149.972 63.224"
        />
        <line
          className="cls-5"
          x1="138.8764"
          y1="46.7274"
          x2="149.9715"
          y2="46.7274"
        />
        <line
          className="cls-5"
          x1="65.7401"
          y1="107.5804"
          x2="38.0177"
          y2="107.5804"
        />
        <line
          className="cls-5"
          x1="53.9688"
          y1="96.458"
          x2="53.9688"
          y2="107.5804"
        />
        <line
          className="cls-5"
          x1="33.2215"
          y1="21.2281"
          x2="60.0891"
          y2="12.4317"
        />
      </g>
      {/* APARTMENTS NUMBERS */}
      <g id="apartment_nr" data-name="apartment nr">
        <g
          className={`
            ${
              sceneName === 'scene_A34_07krs__ita_0000'
                ? 'text-white'
                : elementOnHover === 'A34'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A34') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(7, 34)}
          onMouseOver={() => setElementOnHover('A34')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M50.2566,39.7953H49.365l-.3545-.9219H47.3884l-.3349.9219h-.8692l1.5806-4.0586h.8667ZM48.7478,38.19l-.5591-1.5058L47.64,38.19Z"
          />
          <path
            className="cls-6"
            d="M50.7752,38.7182l.7529-.0909a.7106.7106,0,0,0,.1933.44.531.531,0,0,0,.3824.1523.5229.5229,0,0,0,.4052-.1826.7065.7065,0,0,0,.1651-.4922.66.66,0,0,0-.1577-.4648.5021.5021,0,0,0-.3853-.1719,1.3859,1.3859,0,0,0-.3569.0576l.0859-.6337a.68.68,0,0,0,.4815-.1368.4878.4878,0,0,0,.1665-.3867.4425.4425,0,0,0-.1221-.3262.4381.4381,0,0,0-.3237-.122.4684.4684,0,0,0-.3409.1386.6486.6486,0,0,0-.1713.4043l-.7168-.122a1.6024,1.6024,0,0,1,.2255-.5879,1.0148,1.0148,0,0,1,.4205-.3467,1.4142,1.4142,0,0,1,.6049-.126,1.205,1.205,0,0,1,.919.3662.9246.9246,0,0,1-.2989,1.5274.9476.9476,0,0,1,.5577.3349.9716.9716,0,0,1,.209.6289,1.2183,1.2183,0,0,1-.39.91,1.4561,1.4561,0,0,1-1.8852.06A1.2429,1.2429,0,0,1,50.7752,38.7182Z"
          />
          <path
            className="cls-6"
            d="M55.7644,39.7953v-.8164H54.1033v-.6816L55.864,35.72h.6533v2.5752h.5039v.6836h-.5039v.8164Zm0-1.5V36.9076l-.9331,1.3877Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A35_07krs__lansi_0000'
                ? 'text-white'
                : elementOnHover === 'A35'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A35') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(7, 35)}
          onMouseOver={() => setElementOnHover('A35')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M32.1975,86.4691h-.8916l-.3545-.9218H29.3293l-.3349.9218h-.8692l1.5806-4.0585h.8667Zm-1.5088-1.6054L30.13,83.3578l-.5483,1.5059Z"
          />
          <path
            className="cls-6"
            d="M32.7161,85.392l.7529-.0908a.71.71,0,0,0,.1934.4394.5309.5309,0,0,0,.3823.1524A.5235.5235,0,0,0,34.45,85.71a.7071.7071,0,0,0,.165-.4922.66.66,0,0,0-.1577-.4649.502.502,0,0,0-.3853-.1718,1.3822,1.3822,0,0,0-.3569.0576l.0859-.6338a.6807.6807,0,0,0,.4815-.1367.488.488,0,0,0,.1665-.3868.42.42,0,0,0-.4458-.4482.4679.4679,0,0,0-.3408.1387.6478.6478,0,0,0-.1714.4043l-.7168-.1221A1.6006,1.6006,0,0,1,33,82.8666a1.0138,1.0138,0,0,1,.42-.3467,1.4143,1.4143,0,0,1,.605-.1259,1.2046,1.2046,0,0,1,.9189.3662.9244.9244,0,0,1-.2988,1.5273.9472.9472,0,0,1,.5576.335.9713.9713,0,0,1,.209.6289,1.2181,1.2181,0,0,1-.39.91,1.4561,1.4561,0,0,1-1.8852.06A1.2427,1.2427,0,0,1,32.7161,85.392Z"
          />
          <path
            className="cls-6"
            d="M36.1912,85.4252l.7749-.08a.6668.6668,0,0,0,.1967.417.5323.5323,0,0,0,.3765.1533.5214.5214,0,0,0,.4126-.1982.9.9,0,0,0,.1685-.5957.8143.8143,0,0,0-.1675-.5606.5566.5566,0,0,0-.4361-.1875.7928.7928,0,0,0-.6005.2959l-.6309-.0908.3984-2.1123h2.0567v.7285H37.2737l-.1221.6885a1.1868,1.1868,0,0,1,.5318-.13,1.1691,1.1691,0,0,1,.8774.377,1.3553,1.3553,0,0,1,.36.9765,1.4683,1.4683,0,0,1-.2906.8946,1.288,1.288,0,0,1-1.0991.5371,1.3649,1.3649,0,0,1-.9165-.3018A1.2414,1.2414,0,0,1,36.1912,85.4252Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A36_07krs__lansi_0000'
                ? 'text-white'
                : elementOnHover === 'A36'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A36') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(7, 36)}
          onMouseOver={() => setElementOnHover('A36')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M69.4485,86.47h-.8916l-.3545-.9219H66.58l-.3349.9219h-.8692l1.5806-4.0586h.8667ZM67.94,84.8647l-.5591-1.5059-.5483,1.5059Z"
          />
          <path
            className="cls-6"
            d="M69.967,85.393l.753-.0908a.7105.7105,0,0,0,.1933.4394.5311.5311,0,0,0,.3824.1524.523.523,0,0,0,.4052-.1827.7065.7065,0,0,0,.1651-.4922.66.66,0,0,0-.1577-.4648.5021.5021,0,0,0-.3853-.1719,1.3859,1.3859,0,0,0-.3569.0576l.0859-.6337a.68.68,0,0,0,.4815-.1368.4876.4876,0,0,0,.1665-.3867.4425.4425,0,0,0-.1221-.3262.4381.4381,0,0,0-.3237-.122.4684.4684,0,0,0-.3409.1386.6492.6492,0,0,0-.1714.4043l-.7167-.122a1.6024,1.6024,0,0,1,.2255-.5879,1.0151,1.0151,0,0,1,.42-.3467,1.415,1.415,0,0,1,.605-.126,1.205,1.205,0,0,1,.919.3662.9246.9246,0,0,1-.2989,1.5274.9476.9476,0,0,1,.5577.3349.9716.9716,0,0,1,.2089.6289,1.2182,1.2182,0,0,1-.39.91,1.4561,1.4561,0,0,1-1.8852.06A1.2434,1.2434,0,0,1,69.967,85.393Z"
          />
          <path
            className="cls-6"
            d="M76.0662,83.4057l-.753.083a.5444.5444,0,0,0-.1435-.3428.4188.4188,0,0,0-.3018-.1113.5109.5109,0,0,0-.417.2217,1.8663,1.8663,0,0,0-.2143.9218.9108.9108,0,0,1,.7226-.3437,1.1037,1.1037,0,0,1,.8345.3711,1.3463,1.3463,0,0,1,.3472.958,1.3729,1.3729,0,0,1-.3653.999,1.33,1.33,0,0,1-1.9487-.1006,2.4537,2.4537,0,0,1-.396-1.5654,2.5018,2.5018,0,0,1,.4126-1.6084,1.3325,1.3325,0,0,1,1.0713-.4932,1.1413,1.1413,0,0,1,.7656.2588A1.2166,1.2166,0,0,1,76.0662,83.4057ZM74.303,85.1029a.8806.8806,0,0,0,.1743.585.5121.5121,0,0,0,.3989.2061.4524.4524,0,0,0,.36-.169.8506.8506,0,0,0,.1435-.5527.8883.8883,0,0,0-.1547-.58.4978.4978,0,0,0-.7666-.0088A.7622.7622,0,0,0,74.303,85.1029Z"
          />
        </g>
        <g>
          <path
            className="cls-6"
            d="M92.9075,86.4691h-.8916l-.3545-.9218H90.0393l-.335.9218h-.8691l1.5806-4.0585h.8667Zm-1.5088-1.6054L90.84,83.3578l-.5483,1.5059Z"
          />
          <path
            className="cls-6"
            d="M93.426,85.392l.753-.0908a.71.71,0,0,0,.1933.4394.5311.5311,0,0,0,.3823.1524A.5232.5232,0,0,0,95.16,85.71a.7066.7066,0,0,0,.1651-.4922.66.66,0,0,0-.1578-.4649.5016.5016,0,0,0-.3852-.1718,1.3816,1.3816,0,0,0-.3569.0576l.0859-.6338a.68.68,0,0,0,.4814-.1367.4877.4877,0,0,0,.1665-.3868.42.42,0,0,0-.4458-.4482.468.468,0,0,0-.3408.1387.6489.6489,0,0,0-.1714.4043l-.7168-.1221a1.6006,1.6006,0,0,1,.2256-.5879,1.0145,1.0145,0,0,1,.42-.3467,1.415,1.415,0,0,1,.605-.1259,1.2048,1.2048,0,0,1,.919.3662.9245.9245,0,0,1-.2989,1.5273.9466.9466,0,0,1,.5576.335.9707.9707,0,0,1,.209.6289,1.2184,1.2184,0,0,1-.39.91,1.4563,1.4563,0,0,1-1.8853.06A1.2431,1.2431,0,0,1,93.426,85.392Z"
          />
          <path
            className="cls-6"
            d="M96.89,83.1889v-.7227h2.66v.5645a4.1973,4.1973,0,0,0-.67.9306,5.46,5.46,0,0,0-.519,1.2881,4.81,4.81,0,0,0-.1758,1.22h-.75a5.2054,5.2054,0,0,1,.3477-1.7158,5.6936,5.6936,0,0,1,.8759-1.5644Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A38_07krs__etela_0000'
                ? 'text-white'
                : elementOnHover === 'A38'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A38') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(7, 38)}
          onMouseOver={() => setElementOnHover('A38')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M127.5955,74.5277h-.8916l-.3545-.9218h-1.6221l-.335.9218h-.8691l1.5811-4.0586h.8662Zm-1.5088-1.6054-.5586-1.5059-.5488,1.5059Z"
          />
          <path
            className="cls-6"
            d="M128.114,73.4506l.7529-.0908a.7161.7161,0,0,0,.1934.4394.5322.5322,0,0,0,.3828.1524.5249.5249,0,0,0,.4053-.1826.7089.7089,0,0,0,.165-.4922.6576.6576,0,0,0-.1582-.4649.5008.5008,0,0,0-.3847-.1719,1.3869,1.3869,0,0,0-.3575.0577l.086-.6338a.68.68,0,0,0,.4814-.1367.4865.4865,0,0,0,.167-.3868.42.42,0,0,0-.4463-.4482.4687.4687,0,0,0-.3408.1387.6454.6454,0,0,0-.1709.4043l-.7168-.1221a1.6006,1.6006,0,0,1,.2256-.5879,1.0129,1.0129,0,0,1,.42-.3467,1.4173,1.4173,0,0,1,.6055-.126,1.2045,1.2045,0,0,1,.9189.3663.9244.9244,0,0,1-.2988,1.5273.9484.9484,0,0,1,.5576.335.9707.9707,0,0,1,.209.6289,1.2172,1.2172,0,0,1-.3906.91,1.4556,1.4556,0,0,1-1.8848.06A1.2436,1.2436,0,0,1,128.114,73.4506Z"
          />
          <path
            className="cls-6"
            d="M132.2459,72.3383a.87.87,0,0,1-.5762-.8389.9827.9827,0,0,1,.3164-.75,1.5122,1.5122,0,0,1,1.8,0,.9782.9782,0,0,1,.3193.75.8831.8831,0,0,1-.1465.502.929.929,0,0,1-.4121.3369,1.09,1.09,0,0,1,.5137.3955,1.0488,1.0488,0,0,1,.1758.6006,1.2259,1.2259,0,0,1-.3584.914,1.3051,1.3051,0,0,1-.9541.3516,1.4439,1.4439,0,0,1-.9219-.291,1.1335,1.1335,0,0,1-.4346-.9414,1.167,1.167,0,0,1,.1631-.6045A1.0514,1.0514,0,0,1,132.2459,72.3383Zm.0878.9551a.7123.7123,0,0,0,.1651.5009.533.533,0,0,0,.4111.18.5173.5173,0,0,0,.3985-.1728.7165.7165,0,0,0,.1582-.5.6458.6458,0,0,0-.1612-.458.53.53,0,0,0-.4072-.1729.4878.4878,0,0,0-.4248.1963A.7239.7239,0,0,0,132.3337,73.2934Zm.0723-1.7383a.4874.4874,0,0,0,.1318.3623.4734.4734,0,0,0,.35.13.4556.4556,0,0,0,.4873-.4951.4729.4729,0,0,0-.1319-.3506.466.466,0,0,0-.3467-.1309.483.483,0,0,0-.3574.1328A.4723.4723,0,0,0,132.406,71.5551Z"
          />
        </g>
        <g
          className={`
            ${
              sceneName === 'scene_A39_07krs__etela_0000'
                ? 'text-white'
                : elementOnHover === 'A39'
                ? 'text-white'
                : ''
            }
              ${
                getApartmentStatus(apartments, 'A39') === 'sold'
                  ? 'text-white-sold'
                  : ''
              }
          `}
          onClick={() => changeView(7, 39)}
          onMouseOver={() => setElementOnHover('A39')}
          onMouseLeave={() => setElementOnHover('')}
        >
          <path
            className="cls-6"
            d="M127.5964,30.5126h-.8916l-.3545-.9219h-1.622l-.335.9219h-.8691l1.58-4.0586h.8671Zm-1.5087-1.6055-.56-1.5058-.5479,1.5058Z"
          />
          <path
            className="cls-6"
            d="M128.115,29.4355l.7529-.0909a.7055.7055,0,0,0,.1934.4395.53.53,0,0,0,.3818.1523.5216.5216,0,0,0,.4053-.1826.7045.7045,0,0,0,.165-.4922.6613.6613,0,0,0-.1572-.4648.5027.5027,0,0,0-.3857-.1719,1.38,1.38,0,0,0-.3565.0576l.086-.6338a.68.68,0,0,0,.4814-.1367.4888.4888,0,0,0,.166-.3867.4421.4421,0,0,0-.1221-.3262.4366.4366,0,0,0-.3232-.122.4673.4673,0,0,0-.3408.1386.6511.6511,0,0,0-.1719.4043l-.7168-.122a1.5992,1.5992,0,0,1,.2256-.5879,1.016,1.016,0,0,1,.4209-.3467,1.4124,1.4124,0,0,1,.6045-.126,1.2048,1.2048,0,0,1,.9189.3662.9245.9245,0,0,1-.2988,1.5274.9458.9458,0,0,1,.5576.3349.9709.9709,0,0,1,.209.6289,1.22,1.22,0,0,1-.39.91,1.4573,1.4573,0,0,1-1.8858.06A1.2428,1.2428,0,0,1,128.115,29.4355Z"
          />
          <path
            className="cls-6"
            d="M131.5955,29.5741l.7529-.083a.5487.5487,0,0,0,.1435.3408.4319.4319,0,0,0,.3077.11.4988.4988,0,0,0,.4091-.2217,1.8847,1.8847,0,0,0,.2168-.9189.929.929,0,0,1-.7343.3408,1.0939,1.0939,0,0,1-.8262-.37,1.346,1.346,0,0,1-.3467-.9619,1.37,1.37,0,0,1,.3662-.9952,1.3274,1.3274,0,0,1,1.9473.1,2.47,2.47,0,0,1,.3965,1.5713,2.4931,2.4931,0,0,1-.4131,1.6055,1.332,1.332,0,0,1-1.0742.4932,1.1375,1.1375,0,0,1-.77-.2539A1.2245,1.2245,0,0,1,131.5955,29.5741Zm1.7607-1.6992a.8913.8913,0,0,0-.1728-.585.5143.5143,0,0,0-.4-.207.4486.4486,0,0,0-.3584.17.8648.8648,0,0,0-.1426.5577.882.882,0,0,0,.1553.5771.4939.4939,0,0,0,.7646.0068A.7694.7694,0,0,0,133.3562,27.8749Z"
          />
        </g>
      </g>
      {/* CONES */}
      <g id="view">
        {apartmentNumber === 34 ? (
          <g id="A34-3" data-name="A34">
            <path
              id="cone"
              className="cls-7"
              d="M50.8751,26.6806a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location"
              className={`cls-8 ${
                sceneName === 'scene_A34_07krs__ita_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A34'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 35 ? (
          <g id="A35-3" data-name="A35">
            <path
              id="cone-2"
              data-name="cone"
              className="cls-9"
              d="M49.3414,107.3338a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-2"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A35_07krs__lansi_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A35'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 36 ? (
          <g id="A36-3" data-name="A36">
            <path
              id="cone-3"
              data-name="cone"
              className="cls-10"
              d="M62.3756,107.3338a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-3"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A36_07krs__lansi_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A36'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 37 ? (
          <g id="A37-3" data-name="A37">
            <path
              id="cone-4"
              data-name="cone"
              className="cls-11"
              d="M92.6481,99.8943a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-4"
              data-name="location"
              className="cls-8"
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 38 ? (
          <g id="A38-3" data-name="A38">
            <path
              id="cone-5"
              data-name="cone"
              className="cls-12"
              d="M147.55,58.7736a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6l-19.46,44.91h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-5"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A38_07krs__etela_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A38'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
        {apartmentNumber === 39 ? (
          <g id="A39-3" data-name="A39">
            <path
              id="cone-6"
              data-name="cone"
              className="cls-13"
              d="M147.55,46.14a7.4913,7.4913,0,0,1-2.89.58,7.2612,7.2612,0,0,1-2.92-.6L122.28,91.03h43.79Z"
              transform={`rotate(${hLookAt} ${pivots[apartmentNumber][0]} ${pivots[apartmentNumber][1]})`}
            />
            <circle
              id="location-6"
              data-name="location"
              className={`cls-8 ${
                sceneName === 'scene_A39_07krs__etela_0000'
                  ? 'circle-white'
                  : elementOnHover === 'A39'
                  ? 'circle-white'
                  : ''
              }`}
              cx={`${pivots[apartmentNumber][0]}`}
              cy={`${pivots[apartmentNumber][1]}`}
              r="1.66"
            />
          </g>
        ) : null}
      </g>
      {/* ELEVATOR */}
      <g id="elevator">
        <rect
          id="plate-2"
          data-name="plate"
          className="cls-6"
          x="162.4275"
          y="33.9157"
          width="20.3332"
          height="54.7155"
          rx="10.1666"
        />
        <g id="_8" data-name="8">
          <path
            className="cls-14"
            d="M170.71,59.0727v-1.02h3.7539V58.85a5.92,5.92,0,0,0-.9453,1.3125,7.66,7.66,0,0,0-.7324,1.8184,6.7342,6.7342,0,0,0-.2481,1.7207h-1.0585a7.37,7.37,0,0,1,.49-2.4219,8.08,8.08,0,0,1,1.2363-2.207Z"
          />
        </g>
        <g id="down" onClick={() => changeFloor('down')}>
          <path
            className="cls-1"
            d="M172.5941,70.1163a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,1,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M177.2879,76.09l.4267.5689-4.836,3.2715a.4328.4328,0,0,1-.569,0l-4.836-3.2715L167.9,76.09l4.5516,2.987a.2667.2667,0,0,0,.4267,0Z"
          />
        </g>
        <g id="up" onClick={() => changeFloor('up')}>
          <path
            className="cls-1"
            d="M172.5941,37.2947a7.3963,7.3963,0,1,0,0,14.7925,7.3963,7.3963,0,1,0,0-14.7925Z"
          />
          <path
            className="cls-6"
            d="M177.2879,46.1133l.4267-.5689-4.836-3.2715a.4328.4328,0,0,0-.569,0l-4.836,3.2715.4267.5689,4.5516-2.9869a.2666.2666,0,0,1,.4267,0Z"
          />
        </g>
      </g>
    </SVG>
  )
}

const pivots = {
  34: [48.1051, 22.0806],
  35: [46.5714, 102.7338],
  36: [59.6056, 102.7338],
  37: [89.8781, 95.2943],
  38: [144.7798, 54.1736],
  39: [144.7798, 41.5402],
}

const SVG = styled.svg`
  .cls-1 {
    fill: #d5d4cf;
  }

  .cls-2,
  .cls-5 {
    fill: #e5e5e5;
  }

  .cls-3,
  .cls-4 {
    fill: none;
  }

  .cls-3,
  .cls-4,
  .cls-5 {
    stroke: #3d3d3b;
    stroke-miterlimit: 10;
  }

  .cls-3 {
    stroke-width: 1.4173px;
  }

  .cls-4 {
    stroke-width: 0.8504px;
  }

  .cls-5 {
    stroke-width: 0.2835px;
  }

  .cls-6 {
    fill: #3f3f3c;
  }

  .cls-7 {
    fill: url(#linear-gradient);
  }

  .cls-8 {
    fill: #c65805;
  }

  .cls-9 {
    fill: url(#linear-gradient-2);
  }

  .cls-10 {
    fill: url(#linear-gradient-3);
  }

  .cls-11 {
    fill: url(#linear-gradient-4);
  }

  .cls-12 {
    fill: url(#linear-gradient-5);
  }

  .cls-13 {
    fill: url(#linear-gradient-6);
  }

  .cls-14 {
    fill: #e7e7e7;
  }

  #switch-up:hover,
  #switch-down:hover {
    cursor: pointer;

    .st0 {
      transition: fill 0.3s ease;
      fill: var(--secondary-color);
    }
  }

  #up:hover,
  #down:hover {
    cursor: pointer;

    .cls-1 {
      transition: fill 0.3s ease;
      fill: var(--secondary-color);
    }

    .cls-6 {
      transition: fill 0.3s ease;
      fill: var(--light);
    }
  }

  .active-apt {
    fill: var(--primary-color);
  }

  .active {
    .st8 {
      fill: var(--primary-color);
    }
  }

  .on-hover {
    transition: fill 0.3s ease;
    fill: var(--secondary-color);
    cursor: pointer;
  }

  .white-text {
    fill: #ffffff;
    cursor: pointer;
  }

  .switch {
    cursor: pointer;
  }

  .bg-gray {
    fill: var(--color-sold);
    pointer-events: none;
  }

  .text-white .cls-6 {
    fill: #fff;
    cursor: pointer;
  }

  .text-white-sold .cls-6 {
    fill: #fff;
    cursor: pointer;
  }

  .circle-white {
    fill: #fff;
  }

  .reserved {
    fill: yellow;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
`
