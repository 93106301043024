import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyA8-AQ3g_09cJmPE2EHA_LqgxjV0EjzM0I',
  authDomain: 'balcony-projects.firebaseapp.com',
  projectId: 'balcony-projects',
  storageBucket: 'balcony-projects.appspot.com',
  messagingSenderId: '101490961674',
  appId: '1:101490961674:web:ecbfb4e178e609a5989ef3',
}

// Init Firebase
firebase.initializeApp(firebaseConfig)

// Init services
const projectFirestore = firebase.firestore()

// Timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, timestamp }
