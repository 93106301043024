const FLOOR_15_DATA = [
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 108,
    sceneName: 'scene_A108_15krs_6_present',
    sceneName_future: 'scene_A108_15krs_6_future',
    name: 'A108',
    lookAt: '-181, 0, 140',
    face: 'south',

    relatedApartments: {
      16: 119,
      14: 101,
      13: 94,
      12: 87,
      11: 80,
      10: 79,
      9: 64,
      8: 63,
      7: 46,
      6: 45,
      5: 28,
      4: 19,
      3: 10,
      2: 9,
    },
  },
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 110,
    sceneName: 'scene_A110_15krs_3_present',
    sceneName_future: 'scene_A110_15krs_3_future',
    name: 'A110',
    lookAt: '-92, 0, 140',
    face: 'west',

    relatedApartments: {
      16: 119,
      14: 103,
      13: 96,
      12: 89,
      11: 82,
      10: 79,
      9: 66,
      8: 63,
      7: 48,
      6: 38,
      5: 31,
      4: 22,
      3: 15,
      2: 6,
    },
  },
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 111,
    sceneName: 'scene_A111_15krs_1_present',
    sceneName_future: 'scene_A111_15krs_1_future',
    name: 'A111',
    lookAt: '-92, 0, 140',
    face: 'west',

    relatedApartments: {
      16: 119,
      14: 104,
      13: 96,
      12: 89,
      11: 82,
      10: 79,
      9: 66,
      8: 60,
      7: 51,
      6: 42,
      5: 33,
      4: 24,
      3: 15,
      2: 6,
    },
  },
  {
    floor: 15,
    building: 'A',
    apartmentNumber: 112,
    sceneName: 'scene_A112_15krs_9_present',
    sceneName_future: 'scene_A112_15krs_9_future',
    name: 'A112',
    lookAt: '-2, 0, 140',
    face: 'north',

    relatedApartments: {
      16: 119,
      14: 105,
      13: 98,
      12: 91,
      11: 84,
      10: 77,
      9: 70,
      8: 61,
      7: 52,
      6: 43,
      5: 34,
      4: 25,
      3: 16,
      2: 7,
    },
  },
  // {
  //   floor: 15,
  //   building: 'A',
  //   apartmentNumber: 99,
  //   sceneName: 'scene_A99_13krs_8_present',
  //   sceneName_future: 'scene_A99_13krs_8_future',
  //   name: 'A99',
  //   lookAt: '88, 0, 140',
  //   face: 'east',

  //   relatedApartments: {
  //     14: 91,
  //     16: 105,
  //   },
  // },
]

export default FLOOR_15_DATA
