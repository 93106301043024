const FLOOR_8_DATA = [
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 40,
    sceneName: 'scene_A40_08krs__ita_0000',
    sceneName_future: 'scene_A40_08krs__ita_0000',
    name: 'A40',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      7: 34,
      9: 46,
      10: 52,
      11: 57,
      12: 61,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 41,
    sceneName: 'scene_A41_08krs__lansi_0000',
    sceneName_future: 'scene_A41_08krs__lansi_0000',
    name: 'A41',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 7,
      4: 14,
      5: 21,
      6: 28,
      7: 35,
      9: 47,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 42,
    sceneName: 'scene_A42_08krs__lansi_0000',
    sceneName_future: 'scene_A42_08krs__lansi_0000',
    name: 'A42',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 8,
      4: 15,
      5: 22,
      6: 29,
      7: 36,
      9: 48,
      10: 53,
      11: 58,
      12: 62,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 44,
    sceneName: 'scene_A44_08krs__etela_0000',
    sceneName_future: 'scene_A44_08krs__etela_0000',
    name: 'A44',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 3,
      3: 10,
      4: 17,
      5: 24,
      6: 31,
      7: 38,
      9: 50,
      10: 55,
      11: 60,
      12: 64,
    },
  },
  {
    floor: 8,
    building: 'A',
    apartmentNumber: 45,
    sceneName: 'scene_A45_08krs__etela_0000',
    sceneName_future: 'scene_A45_08krs__etela_0000',
    name: 'A45',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 4,
      3: 11,
      4: 18,
      5: 25,
      6: 32,
      7: 39,
      9: 51,
      10: 56,
      11: 60,
      12: 64,
    },
  },
]

export default FLOOR_8_DATA
