import FLOOR_10_DATA from './floor-10'
import FLOOR_11_DATA from './floor-11'
import FLOOR_12_DATA from './floor-12'
import FLOOR_13_DATA from './floor-13'
import FLOOR_14_DATA from './floor-14'
import FLOOR_15_DATA from './floor-15'
import FLOOR_16_DATA from './floor-16'
import FLOOR_2_DATA from './floor-2'
import FLOOR_3_DATA from './floor-3'
import FLOOR_4_DATA from './floor-4'
import FLOOR_5_DATA from './floor-5'
import FLOOR_6_DATA from './floor-6'
import FLOOR_7_DATA from './floor-7'
import FLOOR_8_DATA from './floor-8'
import FLOOR_9_DATA from './floor-9'

export const BUILDING_A_DATA = {
  2: FLOOR_2_DATA,
  3: FLOOR_3_DATA,
  4: FLOOR_4_DATA,
  5: FLOOR_5_DATA,
  6: FLOOR_6_DATA,
  7: FLOOR_7_DATA,
  8: FLOOR_8_DATA,
  9: FLOOR_9_DATA,
  10: FLOOR_10_DATA,
  11: FLOOR_11_DATA,
  12: FLOOR_12_DATA,
  13: FLOOR_13_DATA,
  14: FLOOR_14_DATA,
  15: FLOOR_15_DATA,
  16: FLOOR_16_DATA,
}
