const FLOOR_14_DATA = [
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 101,
    sceneName: 'scene_A101_14krs_6_present',
    sceneName_future: 'scene_A101_14krs_6_future',
    name: 'A101',
    lookAt: '-181, 0, 140',
    face: 'south',

    relatedApartments: {
      16: 119,
      15: 108,
      13: 94,
      12: 87,
      11: 80,
      10: 79,
      9: 64,
      8: 63,
      7: 46,
      6: 45,
      5: 28,
      4: 19,
      3: 10,
      2: 9,
    },
  },
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 102,
    sceneName: 'scene_A102_14krs_5_present',
    sceneName_future: 'scene_A102_14krs_5_future',
    name: 'A102',
    lookAt: '-92, 0, 140',
    face: 'west',

    relatedApartments: {
      16: 119,
      15: 110,
      13: 96,
      12: 88,
      11: 81,
      10: 79,
      9: 66,
      8: 63,
      7: 48,
      6: 38,
      5: 29,
      4: 22,
      3: 11,
      2: 2,
    },
  },
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 103,
    sceneName: 'scene_A103_14krs_3_present',
    sceneName_future: 'scene_A103_14krs_3_future',
    name: 'A103',
    lookAt: '-92, 0, 140',
    face: 'west',

    relatedApartments: {
      16: 119,
      15: 110,
      13: 96,
      12: 89,
      11: 82,
      10: 79,
      9: 66,
      8: 60,
      7: 51,
      6: 42,
      5: 33,
      4: 24,
      3: 15,
      2: 6,
    },
  },
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 104,
    sceneName: 'scene_A104_14krs_1_present',
    sceneName_future: 'scene_A104_14krs_1_future',
    name: 'A104',
    lookAt: '-92, 0, 140',
    face: 'west',

    relatedApartments: {
      16: 119,
      15: 111,
      13: 96,
      12: 89,
      11: 82,
      10: 79,
      9: 66,
      8: 60,
      7: 51,
      6: 42,
      5: 33,
      4: 24,
      3: 15,
      2: 6,
    },
  },
  {
    floor: 14,
    building: 'A',
    apartmentNumber: 105,
    sceneName: 'scene_A105_14krs_9_present',
    sceneName_future: 'scene_A105_14krs_9_future',
    name: 'A105',
    lookAt: '-2, 0, 140',
    face: 'north',

    relatedApartments: {
      16: 119,
      15: 112,
      13: 98,
      12: 91,
      11: 84,
      10: 77,
      9: 70,
      8: 61,
      7: 52,
      6: 43,
      5: 34,
      4: 25,
      3: 16,
      2: 7,
    },
  },
  // {
  //   floor: 14,
  //   building: 'A',
  //   apartmentNumber: 99,
  //   sceneName: 'scene_A99_13krs_8_present',
  //   sceneName_future: 'scene_A99_13krs_8_future',
  //   name: 'A99',
  //   lookAt: '88, 0, 140',
  //   face: 'east',

  //   relatedApartments: {
  //     13: 91,
  //     15: 105,
  //   },
  // },
]

export default FLOOR_14_DATA
