const FLOOR_16_DATA = [
  {
    floor: 16,
    building: 'A',
    apartmentNumber: 119,
    sceneName: 'scene_A119_16krs_9_present',
    sceneName_future: 'scene_A119_16krs_9_future',
    name: 'A119',
    lookAt: '-2, 0, 140',
    face: 'north',

    relatedApartments: {
      15: 112,
      14: 105,
      13: 98,
      12: 91,
      11: 84,
      10: 77,
      9: 70,
      8: 61,
      7: 52,
      6: 43,
      5: 34,
      4: 25,
      3: 16,
      2: 7,
    },
  },
]

export default FLOOR_16_DATA
