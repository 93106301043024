const FLOOR_12_DATA = [
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 61,
    sceneName: 'scene_A61_12krs__ita_0000',
    sceneName_future: 'scene_A61_12krs__ita_0000',
    name: 'A61',
    lookAt: '52.5, 0, 140',
    face: 'north',

    relatedApartments: {
      2: 5,
      3: 6,
      4: 13,
      5: 20,
      6: 27,
      7: 34,
      8: 40,
      9: 46,
      10: 52,
      11: 57,
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 62,
    sceneName: 'scene_A62_12krs__lansi_0000',
    sceneName_future: 'scene_A62_12krs__lansi_0000',
    name: 'A62',
    lookAt: '-109, 0, 140',
    face: 'west',

    relatedApartments: {
      2: 1,
      3: 7,
      4: 14,
      5: 21,
      6: 28,
      7: 35,
      8: 41,
      9: 47,
      10: 53,
      11: 58,
    },
  },
  {
    floor: 12,
    building: 'A',
    apartmentNumber: 64,
    sceneName: 'scene_A64_12krs__etela_0000',
    sceneName_future: 'scene_A64_12krs__etela_0000',
    name: 'A64',
    lookAt: '160, 0, 140',
    face: 'east',

    relatedApartments: {
      2: 3,
      3: 10,
      4: 17,
      5: 24,
      6: 31,
      7: 38,
      8: 44,
      9: 50,
      10: 56,
      11: 60,
    },
  },
]

export default FLOOR_12_DATA
